import {
  format,
  addDays,
  subDays,
  addMinutes,
  subMinutes,
  differenceInDays,
  isWithinInterval,
  intervalToDuration,
  isValid,
} from 'date-fns';
import { ko } from 'date-fns/locale';

const validationDateUtil = (date) => {
  try {
    if (typeof date === 'object') {
    } else if (!isNaN(date) && typeof date === 'number') {
      if (`${date}`.length === 13) {
        date = new Date(date);
      } else {
        date = new Date(date * 1000);
      }
    } else if (typeof date === 'string') {
      date = new Date(date);
    } else {
      date = new Date(date).toString();
      throw 'type should be string, date or number object';
    }
  } catch (error) {
    console.error(error);
  }
  return date;
};

const DateUtil = {
  formatDateOnly: (date, formatType = 'yyyy-MM-dd') => {
    return format(validationDateUtil(date), formatType);
  },

  formatTimeOnly: (date) => {
    return format(validationDateUtil(date), 'HH:mm:ss');
  },

  formatHourOnly: (date) => {
    return format(validationDateUtil(date), 'ha').toLowerCase();
  },

  formatDateTime: (date) => {
    return format(validationDateUtil(date), 'yyyy-MM-dd (HH:mm:ss)');
  },

  format: (date, dateFormatStr) => {
    const validCheckDate = validationDateUtil(date);
    return isValid(validCheckDate)
      ? format(validCheckDate, dateFormatStr)
      : validCheckDate;
  },

  addMinutes: (date, period) => {
    return addMinutes(date, period);
  },

  subMinutes: (date, period) => {
    return subMinutes(date, period);
  },

  addDays: (date, period) => {
    return addDays(date, period);
  },

  subDays: (date, period) => {
    return subDays(date, period);
  },

  isDayDifferent: (dateLeft, dateRight) => {
    if (
      dateLeft.getFullYear() !== dateRight.getFullYear() ||
      dateLeft.getMonth() !== dateRight.getMonth() ||
      dateLeft.getDate() !== dateRight.getDate()
    ) {
      return true;
    }

    return false;
  },

  calculateRemaingDay: (date) => {
    return differenceInDays(date, new Date());
  },

  calculateElapsedDay: (date) => {
    /* 
        # 시간 기준으로 현재와 차이를 일자로 반환
    */
    /* 
        # 주의 
            * 일자가 달라도 24시간 차이가 나지 않으면 두 날짜의 차이는 0일이다.
            * 예시
                - 아래 두시간의 차이는 23시간
                - 2022-01-01 10:00    
                - 2022-01-02 09:00
                ```
                    // 결과 값: 0
                    differenceInDays(
                        new Date(new Date('2022-01-02').setHours(9, 0)),
                        new Date(new Date('2022-01-01').setHours(10, 0))
                    )
                ```
        )
    */
    return differenceInDays(new Date(), date);
  },

  calcDday: (date, option = { prefixSign: false }) => {
    /* 
        # 날짜 기준으로 현재와 차이를 일자로 반환
            * 24시간 차이가 나지 않아도 일자가 바뀌면 일자가 바뀐다.
    */
    let result;
    const prefixSign = option?.prefixSign;
    const currentDate = new Date(new Date().toDateString());
    const targetDate = new Date(new Date(date).toDateString());
    const elapsedDay = (+currentDate - +targetDate) / 1000 / 60 / 60 / 24;

    if (prefixSign) {
      result = elapsedDay >= 0 ? `+${elapsedDay}` : elapsedDay;
    } else {
      result = Math.abs(elapsedDay);
    }

    return result;
  },

  intervalToDuration: (duration) => {
    return intervalToDuration(duration);
  },

  /**
   * millisecond 단위인 duration을 아래와 같은 상황에 맞게 Return 해주는 유틸함수 입니다.
   * - 0 s 초과, 60 s 이하일 경우: ≤ {nn.n}s
   * - 60 s 초과 1h 이하일 경우: {nn}m {nn.n}s
   * - 1h 초과일 경우: {nn}h {nn}m {nn.n}s
   * - 1d 초과일 경우: {dd}d {nn}h {nn}m (초 단위 비노출)
   */
  convertDuration: (duration, isPause) => {
    if (isNaN(Number(duration))) return '-';
    const date = DateUtil.intervalToDuration({
      start: 0,
      end: duration,
    });
    /* {nn.n}s 인 경우 */
    const dotMilliseconds = isPause
      ? duration.toString().padStart(3, '0').slice(-3)
      : duration.toString().padStart(3, '0').slice(-3, -2);

    if (date.days) return `${date.days}d ${date.hours}h ${date.minutes}m`;
    else if (date.hours)
      return `${date.hours}h ${date.minutes}m ${
        Number(dotMilliseconds)
          ? `${date.seconds}.${dotMilliseconds}s`
          : date.seconds + 's'
      }`;
    else if (date.minutes)
      return `${date.minutes}m ${
        Number(dotMilliseconds)
          ? `${date.seconds}.${dotMilliseconds}s`
          : date.seconds + 's'
      }`;
    else
      return Number(dotMilliseconds)
        ? `${date.seconds}.${dotMilliseconds}s`
        : date.seconds + 's';
  },

  isWithinInterval: (firstDate, secondDate, target) => {
    return isWithinInterval(target, {
      start: firstDate,
      end: secondDate,
    });
  },

  getAge: (birth) => {
    // birth를 new Date()객체로 반환하면 Timezone에 따라 달라집니다.
    // 아래는 윤년까지 고려하여 fixed된 birth와 new Date()를 비교하는 로직입니다.
    try {
      const [birthYear, birthMonth, birthDate] = birth
        .split('-')
        .map((el) => +el);
      let now = new Date();
      // 현재 연도에서 생일 연도를 뺀 값을 age 로 한다.
      let age = now.getFullYear() - birthYear;
      // getMonth는 메서드는 0부터 시작하기 때문에 + 1을 해줘야 합니다. (https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth)
      let thisMonth = now.getMonth() + 1;
      let thisDate = now.getDate();

      // 현재 월일 < 태어난 월일 이면 -1
      if (thisMonth < birthMonth) {
        return age - 1;
      }
      if (thisMonth === birthMonth && thisDate < birthDate) {
        return age - 1;
      }
      return age;
    } catch (error) {
      window.location.href = '/';
    }
  },

  formatMs: (date) => parseInt(date.toString().padEnd(13, 0)),

  formatWaveformToDate: (recordingStartMs, waveformIndex) => {
    return DateUtil.format(
      waveformIndex * 4 + recordingStartMs,
      'yy-MM-dd, HH:mm:ss'
    );
  },
};

export default DateUtil;
