import AlertDialog from './AlertDialog';
import ConfirmDialog from './ConfirmDialog';
// import ConfirmReportDialogContainer from 'redux/container/ConfirmReportDialogContainer';
// import ConfirmReportDialog from './ConfirmReportDialog';
// import ForgotPasswordDialog from './ForgotPasswordDialog';
import ForgotPasswordDialogContainer from 'redux/container/dialog/ForgotPasswordDialogContainer';
import UserManagementDialog from './UserManagementDialog';
import AddNewExaminationDialog from './AddNewExaminationDialog';
import AddNewTestDialogContainer from 'redux/container/dialog/AddNewTestDialogContainer';
import UploadDataDialog from './UploadDataDialog';
// import PrintReportDialog from './PrintReportDialog';
// import PrintReportDialogContainer from 'redux/container/PrintReportDialogContainer';
import EditClassLabelDialog from './EditClassLabelDialog';
import AlertExpireDialogContainer from 'redux/container/dialog/AlertExpireDialogContainer';
import AlertNetworkDialog from './AlertNetworkDialog';
import ConfirmDoctorDialog from './ConfirmDoctorDialog';
import FinalConfirmReportDialogContainer from 'redux/container/dialog/FinalConfirmReportDialogContainer';
import NoticeDialog from './NoticeDialog';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AlertDialog: {
    Name: 'AlertDialog',
    Description: 'AlertDialog description',
    Component: AlertDialog,
  },
  ConfirmDialog: {
    Name: 'ConfirmDialog',
    Description: 'ConfirmDialog description',
    Component: ConfirmDialog,
  },
  // ConfirmReportDialog: {
  //   Name: 'ConfirmReportDialog',
  //   Description: 'ConfirmReportDialog description',
  //   Component: ConfirmReportDialogContainer,
  // },
  ForgotPasswordDialog: {
    Name: 'ForgotPasswordDialog',
    Description: 'ForgotPasswordDialog description',
    Component: ForgotPasswordDialogContainer,
  },
  UserManagementDialog: {
    Name: 'UserManagementDialog',
    Description: 'UserManagementDialog description',
    Component: UserManagementDialog,
  },
  AddNewExaminationDialog: {
    Name: 'AddNewExaminationDialog',
    Description: 'AddNewExaminationDialog description',
    Component: AddNewExaminationDialog,
  },
  AddNewTestDialog: {
    Name: 'AddNewTestDialog',
    Description: 'AddNewTestDialog description',
    Component: AddNewTestDialogContainer,
  },
  UploadDataDialog: {
    Name: 'UploadDataDialog',
    Description: 'UploadDataDialog description',
    Component: UploadDataDialog,
  },
  // PrintReportDialog: {
  //   Name: 'PrintReportDialog',
  //   Description: 'PrintReportDialog description',
  //   Component: PrintReportDialogContainer,
  // },
  EditClassLabelDialog: {
    Name: 'EditClassLabelDialog',
    Description: 'EditClassLabelDialog description',
    Component: EditClassLabelDialog,
  },
  AlertExpireDialog: {
    Name: 'AlertExpireDialog',
    Description: 'AlertExpireDialog description',
    Component: AlertExpireDialogContainer,
  },
  AlertNetworkDialog: {
    Name: 'AlertNetworkDialog',
    Description: 'AlertNetworkDialog description',
    Component: AlertNetworkDialog,
  },
  ConfirmDoctorDialog: {
    Name: 'ConfirmDoctorDialog',
    Description: 'ConfirmDoctorDialog description',
    Component: ConfirmDoctorDialog,
  },
  FinalConfirmReportDialog: {
    Name: 'FinalConfirmReportDialog',
    Description: 'FinalConfirmReportDialog description',
    Component: FinalConfirmReportDialogContainer,
  },
  NoticeDialog: {
    Name: 'NoticeDialog',
    Description: 'NoticeDialog description',
    Component: NoticeDialog,
  },
};
