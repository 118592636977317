import { combineReducers } from 'redux';
// Reducers
import dialogReducer from './duck/dialogDuck';
import authReducer from './duck/authDuck';
import medicalStaffsReducer from './duck/medicalStaffsDuck';
import ecgTestsReducer from './duck/ecgTestsDuck';
import ecgTotalReducer from './duck/ecgTotalDuck';
import eventReducer from './duck/eventDuck';
import reportReducer from './duck/reportDuck';
import patchEcgsReducer from './duck/patchEcgsDuck';
import testResultReducer from './duck/testResultDuck';
import beatReviewReducer from './duck/beatReviewDuck';
import hrReviewReducer from './duck/hrReviewDuck';

const rootReducer = combineReducers({
  dialogReducer,
  authReducer,
  medicalStaffsReducer,
  ecgTestsReducer,
  ecgTotalReducer,
  eventReducer,
  reportReducer,
  patchEcgsReducer,
  testResultReducer,
  beatReviewReducer,
  hrReviewReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
