import rfdc from 'rfdc';
import AppColors from 'theme/AppColors';
import {
  BEAT_TYPE,
  ECTOPIC_TYPE,
  EVENT_CONST_TYPES,
  REPORT_SECTION,
} from 'constant/EventConst';
import { CHART_CONST } from 'constant/ChartConst';
import { getEventInfoByQuery } from './EventConstUtil';
import { TEN_SEC_SCRIPT_DETAIL, ECG_CHART_UNIT } from 'constant/ChartEditConst';

const THIRTY_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX;
const rfdcClone = rfdc();

/**
 * @typedef {Object} TransformedRawType Raw API 응답값을 비즈니스 로직에서 참조하기해 가공된 구간 정보구조
 * @property {Timestamp} onsetMs 구간 시작 시간
 * @property {WaveformIndex} onsetWaveformIndex 구간 시작 시간
 * @property {Timestamp} terminationMs 구간 종료 시간
 * @property {WaveformIndex} terminationWaveformIndex 구간 종료 시간
 * @property {number[]} ecgData waveform 데이터 배열
 * @property {Object} beats 구간 beat 정보
 * @property {Object[]} noises 구간 beat type 중 Noise 의 구간 내 위치 정보
 * @property {Object[]} ectopics 구간 beat type 중 Noise 의 구간 내 위치 정보
 * @property {number} avgHr 구간 Heart Rate 평균값
 * @property {Object[]?} ectopicSeries 구간 beat type 중 APC, VPC 시각화를 위한 series 정보
 * @property {Object[]?} noiseSeries 구간 beat type 중 NOISE 시각화를 위한 series 정보
 * @property {Object[]?} beatTypeZones 구간 beat type 시각화를 위한 구간 정보, Highcharts series의 zones 로 표현
 * @property {Object[]?} noiseEvents 구간 beat type 중 Noise 의 구간 내 위치 정보 */
/**
 * Raw API 응답 데이터구조
 * @typedef RawResponseType
 * @property {Timestamp} onsetMs 구간 시작 시간
 * @property {WaveformIndex} onsetWaveformIndex 구간 시작 시간
 * @property {Timestamp} terminationMs 구간 종료 시간
 * @property {WaveformIndex} terminationWaveformIndex 구간 종료 시간
 * @property {number[]} ecgData waveform 데이터 배열
 * @property {number} avgHr 구간 Heart Rate 평균값
 */
/**
 * withBeat 값이 True 인 Raw API 응답값을 LongTermChart, ShortTermChart 에서 필요한 형태로 변환합니다.
 *
 * @param {RawResponseType[]} ecgRawList raw API 로 응답된 구간 데이터
 * @param {BeatEventInfoType[]} recordingStartMs 검사의 측정 시작시간 Timestamp
 * @returns {TransformedRawType[]}
 */
export const mergeRawListNBeatEventInfoList = (
  ecgRawList,
  beatEventInfoList
) => {
  try {
    beatEventInfoList.forEach((beatEventInfo) => {
      const targetIndex = ecgRawList.findIndex(
        (ecgRaw) =>
          ecgRaw.onsetWaveformIndex === beatEventInfo.onsetWaveformIndex &&
          ecgRaw.terminationWaveformIndex ===
            beatEventInfo.terminationWaveformIndex
      );
      if (targetIndex > -1) {
        const originalSeries = ecgRawList[targetIndex].ecgData;
        const ectopicSeries = beatEventInfo.ectopics.map((ectopicInfo) => ({
          data: originalSeries
            .slice(
              ectopicInfo.onsetLocalWaveformIndex,
              ectopicInfo.terminationLocalWaveformIndex
            )
            .map((value, index) => [
              ectopicInfo.onsetLocalWaveformIndex + index,
              value,
            ]),
          color:
            ectopicInfo.beatType === BEAT_TYPE.APC
              ? AppColors.SVE_600
              : AppColors.VE_600,
          zIndex: 5,
        }));
        const noiseSeries = beatEventInfo.noises.map((noiseInfo) => ({
          data: originalSeries
            .slice(
              noiseInfo.onsetLocalWaveformIndex,
              noiseInfo.terminationLocalWaveformIndex
            )
            .map((value, index) => [
              noiseInfo.onsetLocalWaveformIndex + index,
              value,
            ]),
          color: AppColors.MEDIUM_DARK,
          zIndex: 5,
        }));
        ecgRawList[targetIndex] = {
          ...ecgRawList[targetIndex],
          ...beatEventInfo,
          ectopicSeries,
          noiseSeries,
        };
      } else {
        // TODO: 준호 - 예외 처리 필요 상황!!!
        console.log(
          'TestResultDuckUtil.transformRawList',
          ' TODO: 준호 - 예외 처리 필요 상황!!!'
        );
      }
    });
  } catch (error) {
    console.error(
      'TestResultDuckUtil > mergeRawListNBeatEventInfoList\n',
      error
    );
  }

  return ecgRawList;
};

/**
 * withBeat 값이 True 인 Raw API 응답값을 LongTermChart, ShortTermChart 에서 필요한 형태로 변환합니다.
 *
 * @param {Array<{onsetMs, terminationMs, avgHr, minHr, maxHr, ecgData}>} ecgRawList raw API 로 응답된 구간 데이터
 * @returns {TransformedRawType[]}
 */
export const transformRawList = (ecgRawList) => {
  const createAt = new Date().getTime();

  const result = ecgRawList.map((raw) => {
    const baselineWaveformIndex = raw.onsetWaveformIndex;

    return {
      createAt,
      baselineWaveformIndex,
      ...raw,
    };
  });

  return result;
};

/**
 * @typedef {Object} TransformedEventType Time Event 와 Ectopic 정보를 비즈니스 로직에서 참조하기위해 가공된 정보구조
 * @property {Timestamp} createAt 정보 가공 시점
 * @property {Timestamp | undefined} onsetMs 이벤트 시작 시간
 * @property {number | undefined} onsetWaveformIndex 이벤트 시작 waveform 위치
 * @property {Timestamp | undefined} terminationMs 이벤트 종료 시간
 * @property {number | undefined} terminationWaveformIndex 이벤트 종료 waveform 위치
 * @property {string} type 이벤트 구분값, EVENT_CONST_TYPES 참조
 * @property {number | null} timeEventId 이벤트가 Time Event 정보라면, API 조회를 위한 식별값
 * @property {number | null} onsetRPeakIndex 이벤트가 Ectopic 정보라면, API 조회를 위한 식별값
 * @property {number} position 동일 이벤트 그룹 내 순번(type 에 따라 고유 기준으로 정렬된 이벤트 정보의 순번), 1부터 시작
 */
/**
 * 부정맥 이벤트 API 조회 결과를 비즈니스 로직에서 활용할 형태로 변환합니다.
 *
 * @param {Array} timeEventGroups
 * @returns {TransformedEventType[]}
 */
export const transformTimeEvents = (timeEventGroups) => {
  const createAt = new Date().getTime();

  let result = [];

  // Time Event 정보 가공
  for (const iIndex in timeEventGroups) {
    result = [
      ...result,
      ...timeEventGroups[iIndex].map((value, jIndex) => ({
        createAt,
        onsetMs: value.onsetMs,
        terminationMs: value.terminationMs,
        type: getEventInfoByQuery({ timeEventType: value.eventType }).type,
        timeEventId: value.id,
        onsetRPeakIndex: null,
        position: parseInt(jIndex) + 1,
      })),
    ];
  }

  return result;
};

export const mergeLeadOffInfo = (_prevList, _newList) => {
  const prevList = rfdcClone(_prevList);
  const newList = rfdcClone(_newList);

  if (prevList.length === 0) {
    return newList;
  }
  if (newList.length === 0) {
    return prevList;
  }

  return [
    ...prevList,
    ...newList
      .map((newInfo) => {
        const prevMatchedInfo = prevList.find(
          (prevInfo) =>
            !(
              prevInfo.terminationMs < newInfo.onsetMs ||
              newInfo.terminationMs < prevInfo.onsetMs
            )
        );
        if (prevMatchedInfo) {
          prevMatchedInfo.onsetMs = Math.min(
            prevMatchedInfo.onsetMs,
            newInfo.onsetMs
          );
          prevMatchedInfo.terminationMs = Math.max(
            prevMatchedInfo.terminationMs,
            newInfo.terminationMs
          );
          return false;
        } else {
          return newInfo;
        }
      })
      .filter((v) => !!v),
  ];
};

const getHrAvg = (hrList) => {
  let availableBeats = 0;
  const hrSum = hrList.reduce((acc, cur) => {
    if (cur) {
      availableBeats++;
      return acc + cur;
    } else {
      return acc;
    }
  }, 0);
  /**
   * 소수점 0자리 까지
   */
  return parseInt(Number.parseFloat(hrSum / availableBeats).toFixed(0));
};

//
// transformEctopicWithBeats 의 내부 함수들
//
/** Lead-Off 구간 비교용 */
const _isCovered = (eventInfo, onset, termination, type) =>
  !(
    termination <= eventInfo[`onset${type}`] ||
    eventInfo[`termination${type}`] <= onset
  );
/** 30초 구간의 시작 waveformIndex 를 계산 */
const _getOnsetWaveformIndex = (beatWaveformIndex) =>
  beatWaveformIndex - (beatWaveformIndex % THIRTY_SEC_WAVEFORM_LENGTH);
/** 두 waveformIndex 사이의 가운데 지점을 계산 */
const _getMidWaveformIndex = (preWaveformIndex, postWaveformIndex) =>
  !isNaN(preWaveformIndex + postWaveformIndex)
    ? preWaveformIndex + Math.floor((postWaveformIndex - preWaveformIndex) / 2)
    : undefined;
const _filterLeadOffLitByBeatsRange = (
  leadOffList,
  recordingStartMs,
  firstWaveformIndex,
  lastWaveformIndex
) => {
  const totalOnsetWaveformIndex = _getOnsetWaveformIndex(firstWaveformIndex);
  const totalOnsetMs = recordingStartMs + totalOnsetWaveformIndex * 4;
  const totalTerminationWaveformIndex = _getOnsetWaveformIndex(
    lastWaveformIndex - 1 + THIRTY_SEC_WAVEFORM_LENGTH
  );
  const totalTerminationMs =
    recordingStartMs + totalTerminationWaveformIndex * 4;

  return leadOffList
    .filter((value) =>
      _isCovered(value, totalOnsetMs, totalTerminationMs, 'Ms')
    )
    .map((value) => ({
      ...value,
      onsetWaveformIndex: Math.floor((value.onsetMs - recordingStartMs) / 4),
      terminationWaveformIndex: Math.floor(
        (value.terminationMs - recordingStartMs) / 4
      ),
    }));
};
/** Beat 수에 따른 Ectopic Type 값을 반환 */
const _getEctopicTypeFromBeatLength = (beatsLength) => {
  if (beatsLength === 1) return ECTOPIC_TYPE.ISOLATE;
  else if (beatsLength === 2) return ECTOPIC_TYPE.COUPLET;
  else return ECTOPIC_TYPE.RUN;
};
/** Beat Event Info 를 생성 */
const _makeOverEventInfo = (beatEventBuffer, onsetEctopicData) => {
  const {
    preLeadOffInfo,
    postLeadOffInfo,
    preBeatWaveformIndex,
    postBeatWaveformIndex,
  } = beatEventBuffer;
  const [onsetRPeakIndex, waveformIndex] = ((
    onsetRPeakIndex,
    onsetEctopicData,
    someIncludedWaveformIndex,
    waveformIndexInBuffer
  ) => {
    if (onsetRPeakIndex) {
      return [onsetRPeakIndex, waveformIndexInBuffer];
    } else {
      if (
        onsetEctopicData &&
        onsetEctopicData.waveformIndex.includes(someIncludedWaveformIndex)
      ) {
        return [
          onsetEctopicData.waveformIndex[0],
          onsetEctopicData.waveformIndex,
        ];
      } else {
        // console.log(
        //   'transformEctopicWithBeats > _makeOverEventInfo > onsetRPeakIndex',
        //   {
        //     beatEventBuffer,
        //     onsetEctopicData,
        //   }
        // );
        // return null;
        return [someIncludedWaveformIndex, waveformIndexInBuffer];
      }
    }
  })(
    beatEventBuffer.onsetRPeakIndex,
    onsetEctopicData,
    beatEventBuffer.waveformIndex[0],
    beatEventBuffer.waveformIndex
  );
  const ectopicType = _getEctopicTypeFromBeatLength(waveformIndex.length);

  // 조건 1. 직전 Lead-Off 구간있다면, 해당 구간의 termination 지점 사용, 시작 지점 알고 있음
  // 조건 2. 직전 Beat 의 위치를 안다면, 직전 Beat 와 onset 가운데 지점 사용, 시작 지점 알고 있음
  // 조건 3. 30초 구간 시작 지점으로 임의 설정, 시작 지점 모름
  const [onsetWaveformIndex, hasOnsetMarker] = ((
    onsetLeadOffTermination,
    prev,
    onset
  ) => {
    if (onsetLeadOffTermination) {
      return [onsetLeadOffTermination, true];
    } else if (typeof prev === 'number' && prev < onset) {
      return [_getMidWaveformIndex(prev, onset), true];
    } else {
      return [_getOnsetWaveformIndex(onset), false];
    }
  })(
    preLeadOffInfo?.terminationWaveformIndex,
    preBeatWaveformIndex,
    beatEventBuffer.waveformIndex.at(0)
  );
  // 조건 1. 직후 Lead-Off 구간있다면, 해당 구간의 onset 지점 사용, 종료 지점 알고 있음
  // 조건 2. 직후 Beat 의 위치를 안다면, termination 과 직후 Beat 의 가운데 지점 사용, 종료 지점 알고 있음
  // 조건 3. 30초 구간 종료 지점으로 임의 설정, 종료 지점 모름
  const [terminationWaveformIndex, hasTerminationMarker] = ((
    terminationLeadOffOnset,
    termination,
    post
  ) => {
    if (terminationLeadOffOnset) {
      return [terminationLeadOffOnset, true];
    } else if (typeof post === 'number' && termination < post) {
      return [_getMidWaveformIndex(termination, post), true];
    } else {
      return [
        _getOnsetWaveformIndex(termination - 1) + THIRTY_SEC_WAVEFORM_LENGTH,
        false,
      ];
    }
  })(
    postLeadOffInfo?.onsetWaveformIndex,
    beatEventBuffer.waveformIndex.at(-1),
    postBeatWaveformIndex
  );
  return {
    onsetRPeakIndex: onsetRPeakIndex,
    ectopicType,
    beatType: beatEventBuffer.beatType,
    waveformIndex,
    onsetWaveformIndex,
    hasOnsetMarker,
    hasPreLeadOff: !!preLeadOffInfo,
    terminationWaveformIndex,
    hasTerminationMarker,
    hasPostLeadOff: !!postLeadOffInfo,
    type:
      getEventInfoByQuery({
        beatType: beatEventBuffer.beatType,
        ectopicType,
      })?.type ?? EVENT_CONST_TYPES.NOISE,
  };
};

/**
 * Beats API 응답 데이터구조
 * @typedef BeatsResponseType
 * @property {WaveformIndex[]} waveformIndex Beat 의 R-Peak 위치
 * @property {number[]} beatType Beat 의 종류, i.e. 0: Normal, 1: APC, 2: VPC, 3: Noise(Questionable)
 * @property {number[]} hr Beat 의 HR, Noise 가 아닌 직전 Beat 의 R-Peak 거리를 기준으로 HR 계산
 */
/**
 * Ectopic API 응답 데이터구조
 * @typedef EctopicResponseType
 * @property {WaveformIndex[]} waveformIndex Ectopic 의 Beat R-Peak 위치 배열
 * @property {string} ectopicType Ectopic 의 ectopic 종류, i.e. 'ISOLATED', 'COUPLET', 'RUN'
 * @property {number} beatType Ectopic 의 beat 종류, i.e. 1: APC, 2: VPC
 * @property {number} hrMin Ectopic 의 구간 Heart Rate 최소값
 * @property {number} hrMax Ectopic 의 구간 Heart Rate 최대값
 * @property {number} hrAvg Ectopic 의 구간 Heart Rate 평균값
 * @property {number} position 동일 종류의 Ectopic 목록 중 해당 Ectopic 의 순번, 1~
 * @property {number} beatCount Ectopic 의 구간 내 Beat 수
 * @property {boolean} isFastest Ectopic 의 Avg. HR 이 제일 빠름 여부
 * @property {Timestamp} durationMs Ectopic 의 구간 길이, Millisecond 기준
 * @property {Object[]} registeredReport Ectopic 의 리포트 정보 배열
 */
/**
 * Event Marker 의 render 에 필요한 데이터구조
 * @typedef EventMarkerRenderDataType
 * @property {WaveformIndex} onsetLocalWaveformIndex render 되는 구간에서 시작점 위치
 * @property {Boolean} hasOnsetMarker render 되는 구간에서 시작점 포함 여부
 * @property {WaveformIndex} terminationLocalWaveformIndex render 되는 구간에서 종료점 위치
 * @property {Boolean} hasTerminationMarker render 되는 구간에서 종료점 위치
 */
/**
 * 30초(또는 10초) 구간의 Beats 와 Ectopic 구간정보, 그리고 Noise 구간정보 배열
 * @typedef BeatEventInfoType
 * @property {Timestamp} createAt 정보 가공 시간
 * @property {WaveformIndex} onsetWaveformIndex 구간의 시작 waveform index
 * @property {WaveformIndex} terminationWaveformIndex 구간의 종료 waveform index
 * @property {BeatsResponseType} beats 구간의 Beat 정보 구조
 * @property {(TransformedEventType & EventMarkerRenderDataType)[]} noises 구간의 Noise 구간정보 배열
 * @property {(TransformedEventType & EctopicResponseType & EventMarkerRenderDataType)[]} ectopics 구간의 Ectopic 구간정보 배열
 * @property {number} hrAvg 구간의 Heart Rate 평균값
 */

/**
 * 동일한 구간으로 API 조회한 Beat 데이터와 Ectopic 데이터를 가공하여 30초 구간정보로 가공
 * @param {BeatsResponseType} beats
 * @param {TransformedEventType[]} leadOffList
 * @param {Timestamp} recordingStartMs
 * @param {EctopicResponseType?} onsetEctopicData
 * @returns {{WaveformIndex: BeatEventInfoType}} BeatEventInfo 의 onsetWaveformIndex 를 키로 하는 Object
 */
export const transformEctopicWithBeats = (
  beats,
  leadOffList,
  recordingStartMs,
  onsetEctopicData
) => {
  try {
    const createAt = new Date().getTime();

    /** 가공하는 Beats 구간에 대한 Lead-Off 구간 목록 */
    const filteredLeadOffList = _filterLeadOffLitByBeatsRange(
      leadOffList,
      recordingStartMs,
      beats.waveformIndex.at(0),
      beats.waveformIndex.at(-1)
    );

    const thirtySecBeatEventInfoMap = {};
    const noiseInfoList = [];
    const ectopicInfoList = [];

    let curOnsetWaveformIndex = -1;
    let beatsBuffer = {
      waveformIndex: [],
      beatType: [],
      hr: [],
    };
    /*
    {
      preBeatWaveformIndex,
      postBeatWaveformIndex,
      beatType,
      waveformIndex,
      onsetWaveformIndex,
      terminationWaveformIndex,
      type,
      onsetRPeakIndex,
    }
    */
    let beatEventBuffer = null;

    for (const beatIndex in beats.beatType) {
      // 30초 구간의 beats 가공
      if (
        _getOnsetWaveformIndex(beats.waveformIndex[beatIndex]) !==
        curOnsetWaveformIndex
      ) {
        // 이전 30초 구간 종료
        if (curOnsetWaveformIndex > -1) {
          thirtySecBeatEventInfoMap[curOnsetWaveformIndex] = {
            createAt,
            onsetWaveformIndex: curOnsetWaveformIndex,
            terminationWaveformIndex:
              curOnsetWaveformIndex + THIRTY_SEC_WAVEFORM_LENGTH,
            beats: { ...beatsBuffer },
            hrAvg: getHrAvg(beatsBuffer.hr),
          };
        }
        // 신규 30초 구간 시작
        curOnsetWaveformIndex = _getOnsetWaveformIndex(
          beats.waveformIndex[beatIndex]
        );
        beatsBuffer = {
          waveformIndex: [],
          beatType: [],
          hr: [],
        };
      }
      beatsBuffer.waveformIndex = [
        ...beatsBuffer.waveformIndex,
        beats.waveformIndex[beatIndex],
      ];
      beatsBuffer.beatType = [
        ...beatsBuffer.beatType,
        beats.beatType[beatIndex],
      ];
      beatsBuffer.hr = [...beatsBuffer.hr, beats.hr[beatIndex]];

      // Beat Event 또는 Noise 구간 계산
      const leadOffInfo = ((onset, termination) =>
        filteredLeadOffList.find((value) =>
          _isCovered(value, onset, termination, 'WaveformIndex')
        ))(
        beats.waveformIndex[beatIndex - 1] ?? 0,
        beats.waveformIndex[beatIndex]
      );
      if (parseInt(beatIndex) > 0) {
        if (
          beats.beatType[beatIndex] !== beats.beatType[beatIndex - 1] ||
          leadOffInfo
        ) {
          // Beat Type 이 불연속 지점 파악된 지점
          if (!beatEventBuffer) {
            // 구간 시작
            // beatEventBuffer 정보가 없었다가 불연속 점이 생기는 경우는 Normal Beat 에서 abnormal Beat 가 출현한 상황 뿐임
            beatEventBuffer = {
              preLeadOffInfo: leadOffInfo,
              preBeatWaveformIndex: beats.waveformIndex[beatIndex - 1],
              beatType: beats.beatType[beatIndex],
              waveformIndex: [beats.waveformIndex[beatIndex]],
              onsetRPeakIndex: beats.waveformIndex[beatIndex],
              postBeatWaveformIndex: null,
              postLeadOffInfo: null,
            };
          } else {
            // 구간 종료
            beatEventBuffer['postBeatWaveformIndex'] =
              beats.waveformIndex[beatIndex];
            beatEventBuffer['postLeadOffInfo'] = leadOffInfo;
            const eventInfo = _makeOverEventInfo(
              beatEventBuffer,
              onsetEctopicData
            );
            if (eventInfo.beatType === BEAT_TYPE.NOISE) {
              noiseInfoList.push(eventInfo);
            } else if (
              [BEAT_TYPE.APC, BEAT_TYPE.VPC].includes(eventInfo.beatType)
            ) {
              ectopicInfoList.push(eventInfo);
            }

            if (beats.beatType[beatIndex] !== BEAT_TYPE.NORMAL) {
              // 구간 시작
              beatEventBuffer = {
                preLeadOffInfo: leadOffInfo,
                preBeatWaveformIndex: beats.waveformIndex[beatIndex - 1],
                beatType: beats.beatType[beatIndex],
                waveformIndex: [beats.waveformIndex[beatIndex]],
                onsetRPeakIndex: beats.waveformIndex[beatIndex],
                postBeatWaveformIndex: null,
                postLeadOffInfo: null,
              };
            } else {
              // 새로 시작하는 구간 아님
              beatEventBuffer = null;
            }
          }
        } else if (beatEventBuffer?.waveformIndex) {
          // 이벤트 구간 연속 중
          beatEventBuffer.waveformIndex.push(beats.waveformIndex[beatIndex]);
        }
      } else {
        if (beats.beatType[beatIndex] !== BEAT_TYPE.NORMAL) {
          // 구간 시작, 하지만 시작지점 모름
          beatEventBuffer = {
            preLeadOffInfo: leadOffInfo,
            preBeatWaveformIndex: null,
            beatType: beats.beatType[beatIndex],
            waveformIndex: [beats.waveformIndex[beatIndex]],
            onsetRPeakIndex: null,
            postBeatWaveformIndex: null,
            postLeadOffInfo: null,
          };
        }
      }
    }
    // 취합되지 않은 정보 정리
    thirtySecBeatEventInfoMap[curOnsetWaveformIndex] = {
      createAt,
      onsetWaveformIndex: curOnsetWaveformIndex,
      terminationWaveformIndex:
        curOnsetWaveformIndex + THIRTY_SEC_WAVEFORM_LENGTH,
      beats: { ...beatsBuffer },
      hrAvg: getHrAvg(beatsBuffer.hr),
    };

    if (beatEventBuffer) {
      // 구간 종료, 하지만 종료지점 모름
      const lastWaveformIndex = beatEventBuffer.waveformIndex.at(-1);
      beatEventBuffer['postLeadOffInfo'] = ((onset, termination) => {
        const temp = filteredLeadOffList.find((value) =>
          _isCovered(value, onset, termination, 'WaveformIndex')
        );
        return temp ? JSON.parse(JSON.stringify(temp)) : null;
      })(lastWaveformIndex, lastWaveformIndex + THIRTY_SEC_WAVEFORM_LENGTH);
      beatEventBuffer['postBeatWaveformIndex'] = null;
      const eventInfo = _makeOverEventInfo(beatEventBuffer, onsetEctopicData);
      eventInfo.type === EVENT_CONST_TYPES.NOISE
        ? noiseInfoList.push(eventInfo)
        : ectopicInfoList.push(eventInfo);
      beatEventBuffer = null;
    }

    // 각 구간에 Event Info. 할당
    for (const key in thirtySecBeatEventInfoMap) {
      const onsetWaveformIndex = parseInt(key);
      const terminationWaveformIndex =
        onsetWaveformIndex + THIRTY_SEC_WAVEFORM_LENGTH;
      thirtySecBeatEventInfoMap[onsetWaveformIndex].noises = rfdcClone(
        noiseInfoList.filter((noiseInfo) => {
          return !(
            terminationWaveformIndex <= noiseInfo.onsetWaveformIndex ||
            onsetWaveformIndex >= noiseInfo.terminationWaveformIndex
          );
        })
      );
      thirtySecBeatEventInfoMap[onsetWaveformIndex].ectopics = rfdcClone(
        ectopicInfoList.filter((ectopicInfo) => {
          return !(
            terminationWaveformIndex <= ectopicInfo.onsetWaveformIndex ||
            onsetWaveformIndex >= ectopicInfo.terminationWaveformIndex
          );
        })
      );
    }

    return thirtySecBeatEventInfoMap;
  } catch (error) {
    console.error('TestResultDuckUtil > transformEctopicWithBeats\n', error);
  }
};

/** 특정 Beat 가 포함된 Event 검색을 위한 Find Function 을 반환 */
const _getSomeBeatIncludedEventFindFunction = (beatWaveformIndex) => (value) =>
  value.waveformIndex.includes(beatWaveformIndex);
/** Ectopic 식별값이 동일한 Event 검색을 위한 Find Function 을 반환 */
const _getSameRPeakIndexEventFindFunction = (targetRPeakIndex) => (value) =>
  value.onsetRPeakIndex === targetRPeakIndex;

const _findSameEctopicInfos = ({
  prevMap,
  startKey,
  direction,
  findFunction,
  ectopicList,
}) => {
  /** prevMap 의 Key Waveform Index List */
  const prevKeyList = Object.keys(prevMap).map((value) => parseInt(value));

  let prevMapKey = startKey + THIRTY_SEC_WAVEFORM_LENGTH * direction;
  if (!prevKeyList.includes(prevMapKey)) return undefined;

  const prevEctopic = [
    ...prevMap[prevMapKey].ectopics,
    ...prevMap[prevMapKey].noises,
  ].find(findFunction);
  if (prevEctopic) {
    ectopicList.push(prevEctopic);

    for (
      prevMapKey = prevMapKey + THIRTY_SEC_WAVEFORM_LENGTH * direction;
      prevKeyList.includes(prevMapKey);
      prevMapKey = prevMapKey + THIRTY_SEC_WAVEFORM_LENGTH * direction
    ) {
      const prevContinuousEctopic = [
        ...prevMap[prevMapKey].ectopics,
        ...prevMap[prevMapKey].noises,
      ].find(_getSameRPeakIndexEventFindFunction(prevEctopic.onsetRPeakIndex));
      if (prevContinuousEctopic) {
        ectopicList.push(prevContinuousEctopic);
      } else {
        break;
      }
    }
  }
  return prevEctopic;
};

/**
 * 두 Beat Event Info Map 을 합친다.
 *
 * @param {*} prevMap 기존 확보된 Beat Event Info Map
 * @param {*} newMap 새로 확보된 Beat Event Info Map
 * @returns 신규 Beat Event Info 가 합쳐진 전체 Map
 */
export const mergeBeatEventInfoMap = (_prevMap, _newMap) => {
  performance.mark('mergeBeatEventInfoMap-started');
  const prevMap = rfdcClone(_prevMap);
  const newMap = rfdcClone(_newMap);

  // ectopic 구간정보
  let beforeContinuousList = [];
  let beforeContinuousListFromNewMap = [];
  let beforeDiscontinuousList = [];
  let afterContinuousList = [];
  let afterContinuousListFromNewMap = [];
  let afterDiscontinuousList = [];

  /** newMap 의 Key Waveform Index List */
  const newKeyList = Object.keys(newMap)
    .map((value) => parseInt(value))
    .sort((a, b) => a - b);

  const prevMapBeforeLastBeatWI = (() => {
    let prevMapKey = newKeyList.at(0) - THIRTY_SEC_WAVEFORM_LENGTH;
    let result = undefined;
    while (prevMapKey in prevMap) {
      result = prevMap[prevMapKey].beats.waveformIndex.at(-1);
      if (isNaN(result)) prevMapKey -= THIRTY_SEC_WAVEFORM_LENGTH;
      else break;
    }
    return result;
  })();
  const newMapFirstBeatWI = newMap[newKeyList.at(0)].beats.waveformIndex.at(0);
  const newMapLastBeatWI = newMap[newKeyList.at(-1)].beats.waveformIndex.at(-1);
  const prevMapAfterFirstBeatWI = (() => {
    let prevMapKey = newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH;
    let result = undefined;
    while (prevMapKey in prevMap) {
      result = prevMap[prevMapKey].beats.waveformIndex.at(0);
      if (isNaN(result)) prevMapKey += THIRTY_SEC_WAVEFORM_LENGTH;
      else break;
    }
    return result;
  })();

  // 상향 탐색
  /** newMap 의 처음지점에 걸친 Ectopic 구간 정보 */
  let newStartCoveredEctopic = [
    ...newMap[newKeyList.at(0)].ectopics,
    ...newMap[newKeyList.at(0)].noises,
  ].find(_getSomeBeatIncludedEventFindFunction(newMapFirstBeatWI));
  if (!!newStartCoveredEctopic) {
    beforeContinuousListFromNewMap.push(newStartCoveredEctopic);
    // newMap 의 Ectopic 구간 정보 업데이트 대상 수집
    _findSameEctopicInfos({
      prevMap: newMap,
      startKey: newKeyList.at(0),
      direction: 1,
      findFunction: _getSameRPeakIndexEventFindFunction(
        newStartCoveredEctopic.onsetRPeakIndex
      ),
      ectopicList: beforeContinuousListFromNewMap,
    });
    const continuousEctopic = _findSameEctopicInfos({
      prevMap,
      startKey: newKeyList.at(0),
      direction: -1,
      findFunction: (value) => {
        if (newStartCoveredEctopic.hasPreLeadOff) return false;
        const isSameType = value.beatType === newStartCoveredEctopic.beatType;
        const isIncludeClosedBeat = _getSomeBeatIncludedEventFindFunction(
          prevMapBeforeLastBeatWI
        )(value);

        return isSameType && isIncludeClosedBeat;
      },
      ectopicList: beforeContinuousList,
    });
    if (!continuousEctopic) {
      // 만약 연속 구간 그룹에 prevMap 에서 찾아진것이 없다면, 탐색에서 처음으로 나타난 Ectopic 구간 정보 그룹 확보(beforeDiscontinuousList)
      _findSameEctopicInfos({
        prevMap,
        startKey: newKeyList.at(0),
        direction: -1,
        findFunction: _getSomeBeatIncludedEventFindFunction(
          prevMapBeforeLastBeatWI
        ),
        ectopicList: beforeDiscontinuousList,
      });
    }
  } else {
    // prevMap 에서 상향 탐색으로 처음으로 나타난 Ectopic 구간 정보 그룹 확보(beforeDiscontinuousList)
    _findSameEctopicInfos({
      prevMap,
      startKey: newKeyList.at(0),
      direction: -1,
      findFunction: _getSomeBeatIncludedEventFindFunction(
        prevMapBeforeLastBeatWI
      ),
      ectopicList: beforeDiscontinuousList,
    });
  }
  // 하향 탐색
  /** newMap 의 종료지점에 걸친 Ectopic 구간 정보 */
  let newEndCoveredEctopic = [
    ...newMap[newKeyList.at(-1)].ectopics,
    ...newMap[newKeyList.at(-1)].noises,
  ].find(_getSomeBeatIncludedEventFindFunction(newMapLastBeatWI));
  if (!!newEndCoveredEctopic) {
    afterContinuousListFromNewMap.push(newEndCoveredEctopic);
    // newMap 의 Ectopic 구간 정보 업데이트 대상 수집
    _findSameEctopicInfos({
      prevMap: newMap,
      startKey: newKeyList.at(-1),
      direction: -1,
      findFunction: _getSameRPeakIndexEventFindFunction(
        newEndCoveredEctopic.onsetRPeakIndex
      ),
      ectopicList: afterContinuousListFromNewMap,
    });
    const continuousEctopic = _findSameEctopicInfos({
      prevMap,
      startKey: newKeyList.at(-1),
      direction: 1,
      findFunction: (value) => {
        if (newEndCoveredEctopic.hasPostLeadOff) return false;
        const isSameType = value.beatType === newEndCoveredEctopic.beatType;
        const isIncludeClosedBeat = _getSomeBeatIncludedEventFindFunction(
          prevMapAfterFirstBeatWI
        )(value);

        return isSameType && isIncludeClosedBeat;
      },
      ectopicList: afterContinuousList,
    });
    if (!continuousEctopic) {
      // 만약 연속 구간 그룹에 prevMap 에서 찾아진것이 없다면, 탐색에서 처음으로 나타난 Ectopic 구간 정보 그룹 확보(beforeDiscontinuousList)
      _findSameEctopicInfos({
        prevMap,
        startKey: newKeyList.at(-1),
        direction: 1,
        findFunction: _getSomeBeatIncludedEventFindFunction(
          prevMapAfterFirstBeatWI
        ),
        ectopicList: afterDiscontinuousList,
      });
    }
  } else {
    // prevMap 에서 하향 탐색으로 처음으로 나타난 Ectopic 구간 정보 그룹 확보(beforeDiscontinuousList)
    _findSameEctopicInfos({
      prevMap,
      startKey: newKeyList.at(-1),
      direction: 1,
      findFunction: _getSomeBeatIncludedEventFindFunction(
        prevMapAfterFirstBeatWI
      ),
      ectopicList: afterDiscontinuousList,
    });
  }

  // prevMap 중 더이상 존재하지 않는 Ectopic 구간 정보 제거
  (() => {
    let prevMapKey = newKeyList.at(0) - THIRTY_SEC_WAVEFORM_LENGTH;
    while (prevMapKey in prevMap) {
      const { noises, ectopics, onsetWaveformIndex, terminationWaveformIndex } =
        prevMap[prevMapKey];
      const newNoises = [...noises].filter((beatEventInfo) =>
        beatEventInfo.waveformIndex.some(
          (value) =>
            onsetWaveformIndex <= value && value < terminationWaveformIndex
        )
      );
      const newEctopics = [...ectopics].filter((beatEventInfo) =>
        beatEventInfo.waveformIndex.some(
          (value) =>
            onsetWaveformIndex <= value && value < terminationWaveformIndex
        )
      );
      if (
        newNoises.length === prevMap[prevMapKey].noises.length &&
        newEctopics.length === prevMap[prevMapKey].ectopics.length
      )
        break;
      else {
        prevMap[prevMapKey].noises = newNoises;
        prevMap[prevMapKey].ectopics = newEctopics;
        prevMapKey -= THIRTY_SEC_WAVEFORM_LENGTH;
      }
    }
  })();
  (() => {
    let prevMapKey = newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH;
    while (prevMapKey in prevMap) {
      const { noises, ectopics, onsetWaveformIndex, terminationWaveformIndex } =
        prevMap[prevMapKey];
      const newNoises = [...noises].filter((beatEventInfo) =>
        beatEventInfo.waveformIndex.some(
          (value) =>
            onsetWaveformIndex <= value && value < terminationWaveformIndex
        )
      );
      const newEctopics = [...ectopics].filter((beatEventInfo) =>
        beatEventInfo.waveformIndex.some(
          (value) =>
            onsetWaveformIndex <= value && value < terminationWaveformIndex
        )
      );
      if (
        newNoises.length === prevMap[prevMapKey].noises.length &&
        newEctopics.length === prevMap[prevMapKey].ectopics.length
      )
        break;
      else {
        prevMap[prevMapKey].noises = newNoises;
        prevMap[prevMapKey].ectopics = newEctopics;
        prevMapKey += THIRTY_SEC_WAVEFORM_LENGTH;
      }
    }
  })();

  // 목록 정리
  if (
    beforeDiscontinuousList.length === 0 &&
    afterDiscontinuousList.length === 0 &&
    newStartCoveredEctopic?.onsetRPeakIndex ===
      newEndCoveredEctopic?.onsetRPeakIndex &&
    !!newStartCoveredEctopic
  ) {
    // `beforeDisContinuousList` 와 `afterDiscontinuousList` 이 둘다 비어있으면서  `beforeContinuousList` 의 마지막 요소와 `afterContinuousList` 의 첫 번째 요소가 동일한 경우
    //   - 하나의 Ectopic 정보로 모든 구간정보 업데이트
    const onsetRPeakIndex = newStartCoveredEctopic.onsetRPeakIndex;
    const waveformIndex = newStartCoveredEctopic.waveformIndex;
    const ectopicType = newStartCoveredEctopic.ectopicType;
    const type = newStartCoveredEctopic.type;
    const onsetWaveformIndex = [
      newStartCoveredEctopic,
      ...beforeContinuousList,
    ].at(-1).onsetWaveformIndex;
    const hasOnsetMarker = [newStartCoveredEctopic, ...beforeContinuousList].at(
      -1
    ).hasOnsetMarker;
    const terminationWaveformIndex = [
      newStartCoveredEctopic,
      ...afterContinuousList,
    ].at(-1).terminationWaveformIndex;
    const hasTerminationMarker = [
      newStartCoveredEctopic,
      ...afterContinuousList,
    ].at(-1).hasTerminationMarker;
    for (let ectopicInfo of [
      ...beforeContinuousList,
      ...beforeContinuousListFromNewMap,
      ...afterContinuousList,
    ]) {
      ectopicInfo.onsetRPeakIndex = onsetRPeakIndex;
      ectopicInfo.waveformIndex = waveformIndex;
      ectopicInfo.ectopicType = ectopicType;
      ectopicInfo.type = type;
      ectopicInfo.onsetWaveformIndex = onsetWaveformIndex;
      ectopicInfo.hasOnsetMarker = hasOnsetMarker;
      ectopicInfo.terminationWaveformIndex = terminationWaveformIndex;
      ectopicInfo.hasTerminationMarker = hasTerminationMarker;
    }
    // 이웃 map 에 전파
    if (
      beforeContinuousList.length === 0 &&
      onsetWaveformIndex < newKeyList.at(0)
    ) {
      if (type === EVENT_CONST_TYPES.NOISE)
        prevMap[newKeyList.at(0) - THIRTY_SEC_WAVEFORM_LENGTH].noises.push(
          newStartCoveredEctopic
        );
      else
        prevMap[newKeyList.at(0) - THIRTY_SEC_WAVEFORM_LENGTH].ectopics.push(
          newStartCoveredEctopic
        );
    }
  } else {
    // newMap 이전(before*) 목록 정리
    const edgeStartWaveformIndex = _getMidWaveformIndex(
      prevMapBeforeLastBeatWI,
      newMapFirstBeatWI
    );
    if (beforeDiscontinuousList.length > 0) {
      const excludeEdgeIndex =
        beforeDiscontinuousList[0].waveformIndex.findIndex(
          (value) => newMapFirstBeatWI <= value
        );
      const waveformIndex =
        excludeEdgeIndex === -1
          ? beforeDiscontinuousList[0].waveformIndex
          : [...beforeDiscontinuousList[0].waveformIndex].slice(
              0,
              excludeEdgeIndex
            );
      const ectopicType = _getEctopicTypeFromBeatLength(waveformIndex.length);
      const type =
        getEventInfoByQuery({
          beatType: beforeDiscontinuousList[0].beatType,
          ectopicType,
        })?.type ?? EVENT_CONST_TYPES.NOISE;

      for (let ectopicInfo of beforeDiscontinuousList) {
        ectopicInfo.waveformIndex = waveformIndex;
        ectopicInfo.ectopicType = ectopicType;
        ectopicInfo.type = type;
        ectopicInfo.terminationWaveformIndex =
          edgeStartWaveformIndex || newKeyList.at(0);
        ectopicInfo.hasTerminationMarker = !!edgeStartWaveformIndex;
      }
      for (let ectopicInfo of [
        ...beforeContinuousList,
        ...beforeContinuousListFromNewMap,
      ]) {
        ectopicInfo.onsetWaveformIndex =
          edgeStartWaveformIndex || newKeyList.at(0);
        ectopicInfo.hasOnsetMarker = !!edgeStartWaveformIndex;
      }

      // 이웃 map 에 전파
      if (newKeyList.at(0) < edgeStartWaveformIndex) {
        if (type === EVENT_CONST_TYPES.NOISE)
          newMap[newKeyList.at(0)].noises.push(beforeDiscontinuousList.at(0));
        else
          newMap[newKeyList.at(0)].ectopics.push(beforeDiscontinuousList.at(0));
      }
    } else if (!!newStartCoveredEctopic) {
      const onsetRPeakIndex = newStartCoveredEctopic.onsetRPeakIndex;
      const waveformIndex = newStartCoveredEctopic.waveformIndex;
      const ectopicType = newStartCoveredEctopic.ectopicType;
      const type = newStartCoveredEctopic.type;
      const onsetWaveformIndex = (() => {
        if (beforeContinuousList.length === 0) {
          if (newStartCoveredEctopic.hasOnsetMarker) {
            return newStartCoveredEctopic.onsetWaveformIndex;
          } else {
            return edgeStartWaveformIndex || newKeyList.at(0);
          }
        } else {
          return beforeContinuousList.at(-1).onsetWaveformIndex;
        }
      })();
      const hasOnsetMarker = (() => {
        if (beforeContinuousList.length === 0) {
          if (newStartCoveredEctopic.hasOnsetMarker) {
            return newStartCoveredEctopic.hasOnsetMarker;
          } else {
            return !!edgeStartWaveformIndex;
          }
        } else {
          return beforeContinuousList.at(-1).hasOnsetMarker;
        }
      })();
      const terminationWaveformIndex =
        newStartCoveredEctopic.terminationWaveformIndex;
      const hasTerminationMarker = newStartCoveredEctopic.hasTerminationMarker;

      for (let ectopicInfo of [
        ...beforeContinuousList,
        ...beforeContinuousListFromNewMap,
      ]) {
        ectopicInfo.onsetRPeakIndex = onsetRPeakIndex;
        ectopicInfo.waveformIndex = waveformIndex;
        ectopicInfo.ectopicType = ectopicType;
        ectopicInfo.type = type;
        ectopicInfo.onsetWaveformIndex = onsetWaveformIndex;
        ectopicInfo.hasOnsetMarker = hasOnsetMarker;
        ectopicInfo.terminationWaveformIndex = terminationWaveformIndex;
        ectopicInfo.hasTerminationMarker = hasTerminationMarker;
      }

      // 이웃 map 에 전파
      if (
        beforeContinuousList.length === 0 &&
        onsetWaveformIndex < newKeyList.at(0)
      ) {
        if (type === EVENT_CONST_TYPES.NOISE)
          prevMap[newKeyList.at(0) - THIRTY_SEC_WAVEFORM_LENGTH].noises.push(
            newStartCoveredEctopic
          );
        else
          prevMap[newKeyList.at(0) - THIRTY_SEC_WAVEFORM_LENGTH].ectopics.push(
            newStartCoveredEctopic
          );
      }
    }

    // newMap 이후(after*) 목록 정리
    const edgeEndWaveformIndex = _getMidWaveformIndex(
      newMapLastBeatWI,
      prevMapAfterFirstBeatWI
    );
    if (afterDiscontinuousList.length > 0) {
      const includeEdgeIndex =
        afterDiscontinuousList[0].waveformIndex.findIndex(
          (value) => newMapLastBeatWI < value
        );
      const waveformIndex =
        includeEdgeIndex === -1
          ? afterDiscontinuousList[0].waveformIndex
          : [...afterDiscontinuousList[0].waveformIndex].slice(
              includeEdgeIndex
            );
      const onsetRPeakIndex = waveformIndex.at(0);
      const ectopicType = _getEctopicTypeFromBeatLength(waveformIndex.length);
      const type =
        getEventInfoByQuery({
          beatType: afterDiscontinuousList[0].beatType,
          ectopicType,
        })?.type ?? EVENT_CONST_TYPES.NOISE;

      for (let ectopicInfo of afterDiscontinuousList) {
        ectopicInfo.waveformIndex = waveformIndex;
        ectopicInfo.onsetRPeakIndex = onsetRPeakIndex;
        ectopicInfo.ectopicType = ectopicType;
        ectopicInfo.type = type;
        ectopicInfo.onsetWaveformIndex =
          edgeEndWaveformIndex ||
          newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH;
        ectopicInfo.hasOnsetMarker = !!edgeEndWaveformIndex;
      }
      for (let ectopicInfo of [
        ...afterContinuousList,
        ...afterContinuousListFromNewMap,
      ]) {
        ectopicInfo.terminationWaveformIndex =
          edgeEndWaveformIndex ||
          newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH;
        ectopicInfo.hasTerminationMarker = !!edgeEndWaveformIndex;
      }

      // 이웃 map 에 전파
      if (
        edgeEndWaveformIndex <
        newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH
      ) {
        if (type === EVENT_CONST_TYPES.NOISE)
          newMap[newKeyList.at(-1)].noises.push(afterDiscontinuousList.at(0));
        else
          newMap[newKeyList.at(-1)].ectopics.push(afterDiscontinuousList.at(0));
      } else if (
        newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH < edgeEndWaveformIndex &&
        !!newEndCoveredEctopic
      ) {
        if (newEndCoveredEctopic.type === EVENT_CONST_TYPES.NOISE)
          prevMap[newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH].noises.push(
            newEndCoveredEctopic
          );
        else
          prevMap[newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH].ectopics.push(
            newEndCoveredEctopic
          );
      }
    } else if (!!newEndCoveredEctopic) {
      const onsetRPeakIndex = newEndCoveredEctopic.onsetRPeakIndex;
      const waveformIndex = [...newEndCoveredEctopic.waveformIndex];
      for (let value of [
        ...(afterContinuousList.at(-1)?.waveformIndex ?? []),
      ].filter((wi) => newMapLastBeatWI < wi)) {
        if (!waveformIndex.includes(value)) waveformIndex.push(value);
      }
      waveformIndex.sort((a, b) => a - b);
      const ectopicType = _getEctopicTypeFromBeatLength(waveformIndex.length);
      const type =
        getEventInfoByQuery({
          beatType: newEndCoveredEctopic.beatType,
          ectopicType,
        })?.type ?? EVENT_CONST_TYPES.NOISE;
      const onsetWaveformIndex = newEndCoveredEctopic.onsetWaveformIndex;
      const hasOnsetMarker = newEndCoveredEctopic.hasOnsetMarker;
      const terminationWaveformIndex = (() => {
        if (afterContinuousList.length === 0) {
          if (newEndCoveredEctopic.hasTerminationMarker) {
            return newEndCoveredEctopic.terminationWaveformIndex;
          } else {
            return (
              edgeEndWaveformIndex ||
              newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH
            );
          }
        } else {
          return afterContinuousList.at(-1).terminationWaveformIndex;
        }
      })();
      const hasTerminationMarker = (() => {
        if (afterContinuousList.length === 0) {
          if (newEndCoveredEctopic.hasTerminationMarker) {
            return newEndCoveredEctopic.hasTerminationMarker;
          } else {
            return !!edgeEndWaveformIndex;
          }
        } else {
          return afterContinuousList.at(-1).hasTerminationMarker;
        }
      })();
      for (let ectopicInfo of [
        ...afterContinuousList,
        ...afterContinuousListFromNewMap,
      ]) {
        ectopicInfo.onsetRPeakIndex = onsetRPeakIndex;
        ectopicInfo.waveformIndex = waveformIndex;
        ectopicInfo.ectopicType = ectopicType;
        ectopicInfo.type = type;
        ectopicInfo.onsetWaveformIndex = onsetWaveformIndex;
        ectopicInfo.hasOnsetMarker = hasOnsetMarker;
        ectopicInfo.terminationWaveformIndex = terminationWaveformIndex;
        ectopicInfo.hasTerminationMarker = hasTerminationMarker;
      }

      // 이웃 map 에 전파
      if (
        afterContinuousList.length === 0 &&
        newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH <
          newEndCoveredEctopic.terminationWaveformIndex
      ) {
        if (type === EVENT_CONST_TYPES.NOISE)
          prevMap[newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH].noises.push(
            newEndCoveredEctopic
          );
        else
          prevMap[newKeyList.at(-1) + THIRTY_SEC_WAVEFORM_LENGTH].ectopics.push(
            newEndCoveredEctopic
          );
      }
    }
  }

  const mergedMap = Object.assign(prevMap, newMap);
  performance.mark('mergeBeatEventInfoMap-finished');

  const mergeBeatEventInfoMapMeasure = performance.measure(
    'mergeBeatEventInfoMap-duration',
    'mergeBeatEventInfoMap-started',
    'mergeBeatEventInfoMap-finished'
  );

  // console.log('mergeBeatEventInfoMap', mergeBeatEventInfoMapMeasure.duration, {
  //   mergeBeatEventInfoMapMeasure,
  // });
  return mergedMap;
};

export const getInitRepresentativeStripInfo = (reportEventDetailData) => {
  const {
    reportSection,
    representativeOnsetIndex,
    representativeTerminationIndex,
  } = reportEventDetailData ?? {};

  if (!representativeOnsetIndex || !representativeTerminationIndex) {
    return {
      selectedMs: null,
      representativeOnsetIndex: null,
      representativeTerminationIndex: null,
    };
  } else {
    const interHalfWaveformLength = Math.ceil(
      (representativeTerminationIndex - representativeOnsetIndex) / 2
    );
    return {
      selectedMs:
        reportSection === REPORT_SECTION.ADDITIONAL || !interHalfWaveformLength
          ? null
          : representativeOnsetIndex + interHalfWaveformLength,
      representativeOnsetIndex,
      representativeTerminationIndex,
    };
  }
};

export const getSearchBeatsNEctopicListRangeAfterUpdateEvent = (
  onsetWaveformIndex,
  terminationWaveformIndex
) => {
  let result = {
    searchOnsetRequest: undefined,
    searchTerminationRequest: undefined,
  };
  const xAxisMax = THIRTY_SEC_WAVEFORM_LENGTH;

  const representativeOnsetWaveformIndex =
    onsetWaveformIndex % xAxisMax <= 1250
      ? onsetWaveformIndex - (onsetWaveformIndex % xAxisMax) - xAxisMax
      : onsetWaveformIndex - (onsetWaveformIndex % xAxisMax);
  const representativeTerminationWaveformIndex =
    terminationWaveformIndex - (terminationWaveformIndex % xAxisMax);
  const searchOnsetRequest = representativeOnsetWaveformIndex;
  const searchTerminationRequest =
    representativeTerminationWaveformIndex + xAxisMax;

  result = {
    searchOnsetRequest,
    searchTerminationRequest,
  };

  return result;
};

/* 
  # 10s strip detail 관련 로직
    - detail beat info
    - avg hr
 */
// # 10s strip detail - detail beat info
export const _getFilterBeatsNEctopicList = (
  beatsNEctopicList,
  startTenSecStripWaveformIdx,
  endTenSecStripWaveformIdx
) => {
  let filterBeatsNEctopicList = [];

  for (let waveformIndex in beatsNEctopicList) {
    const parseWaveformIndex = parseInt(waveformIndex);
    if (
      !(
        parseWaveformIndex + 7500 < startTenSecStripWaveformIdx ||
        parseWaveformIndex > endTenSecStripWaveformIdx
      )
    ) {
      filterBeatsNEctopicList.push(beatsNEctopicList[parseWaveformIndex]);
    }
  }

  return filterBeatsNEctopicList;
};

// # 10s strip detail - get list
export const _getTenSecStripInfo = (
  filterBeatsNEctopicList,
  startTenSecStripWaveformIdx,
  endTenSecStripWaveformIdx,
  target
) => {
  let filterTargetData;

  let startBeatWaveformIndex, endBeatWaveformIndex;
  if (filterBeatsNEctopicList.length === 1) {
    startBeatWaveformIndex =
      filterBeatsNEctopicList[0].beats.waveformIndex.findIndex(
        (v) => v > startTenSecStripWaveformIdx
      );

    endBeatWaveformIndex =
      filterBeatsNEctopicList[0].beats.waveformIndex.findLastIndex(
        (v) => v < endTenSecStripWaveformIdx
      );

    filterTargetData = filterBeatsNEctopicList[0].beats[target].slice(
      startBeatWaveformIndex,
      endBeatWaveformIndex + 1
    );
  } else if (filterBeatsNEctopicList.length === 2) {
    startBeatWaveformIndex =
      filterBeatsNEctopicList[0].beats.waveformIndex.findIndex(
        (v) => v > startTenSecStripWaveformIdx
      );

    endBeatWaveformIndex =
      filterBeatsNEctopicList[1].beats.waveformIndex.findLastIndex(
        (v) => v < endTenSecStripWaveformIdx
      );

    const filterTargetData1 = filterBeatsNEctopicList[0].beats[target].slice(
      startBeatWaveformIndex
    );
    const filterTargetData2 = filterBeatsNEctopicList[1].beats[target].slice(
      0,
      endBeatWaveformIndex + 1
    );

    filterTargetData = [...filterTargetData1, ...filterTargetData2];
  } else {
    filterTargetData = [];
  }

  if (target === TEN_SEC_SCRIPT_DETAIL.WAVEFORM_INDEX) {
    filterTargetData = filterTargetData.map(
      (v) => v - startTenSecStripWaveformIdx
    );
  }

  return filterTargetData;
};

// # 10s strip detail - calc avg hr
export const _getHrAvg = (beatsNEctopicList) => {
  let result, sumHr;

  sumHr = beatsNEctopicList.reduce((acc, cur) => acc + cur, 0);
  result =
    Math.floor((sumHr / beatsNEctopicList.filter(Boolean).length) * 10) / 10;

  return result;
};
