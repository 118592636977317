export const CHART_EDIT_CONST = {
  HIGHCHARTS_PLOT_BACKGROUND: 'highcharts-plot-background',
  EVENT_MARKER_GROUP: 'huinno-event-marker-group',
  BEAT_MARKER_GROUP: 'huinno-beat-marker-group',
  BEAT_MARKER: 'huinno-beat-marker',
  MOUSE_TRACKER: 'huinno-mouse-tracker',
  VERTICAL_DOTTED_LINE: 'huinno-vertical-dotted-line',
  BEAT_ADD_PLUS_BUTTON: 'huinno-beat-add-plus-button',
  VERTICAL_BEAT_PLOT_LINE: 'huinno-clicked-beat-plot-line',
  SELECTION_MARKER: 'SELECTION_MARKER',
  SELECTION_HIGHLIGHT: 'SELECTION_HIGHLIGHT',
  MAIN_TENSEC_STRIP: 'MAIN_TENSEC_STRIP',
  EXTRA_TENSEC_STRIP: 'EXTRA_TENSEC_STRIP',
  REPORT_REPRESENTATIVE_STRIP: 'REPORT_REPRESENTATIVE_STRIP',
  TEN_SEC_TSTRIP_MOVE_DIR: {
    PREV: 'PREV',
    NEXT: 'NEXT',
  },
};

export const SELECTION_MARKER_TYPE = {
  ONSET: 'ONSET',
  TERMINATION: 'TERMINATION',
  RESET: 'RESET',
};

export const STRIP_TYPE = {
  SINGLE_LINE: 'SINGLE_LINE',
  MULTI_LINE: {
    ONSET: 'ONSET',
    MIDDLE: 'MIDDLE',
    TERMINATION: 'TERMINATION',
  },
};

export const TEN_SEC_STRIP = {
  TYPE: {
    MAIN: 'main',
    EXTRA: 'extra',
    RESET: 'reset',
  },
  POSITION: {
    PREV: 'PREV',
    NONE: 'NONE',
    NEXT: 'NEXT',
  },
  MOVE_TYPE: {
    PREV: 'PREV',
    NEXT: 'NEXT',
  },
};

export const HIGHCHART_UNIT = {
  LOCATION: 'LOCATION',
  PIXEL: 'PIXEL',
};

export const EDIT_CHART = {
  VALIDATION: {
    EXTEND_SELECTION_STRIP: 'EXTEND_SELECTION_STRIP',
    PREVENT_RENDER_TENSEC_STRIP: 'PREVENT_RENDER_TENSEC_STRIP',
    REMOVE_CONTEXTMENU: 'REMOVE_CONTEXTMENU',
    SHOW_CONTEXTMENU: 'SHOW_CONTEXTMENU',
    REMOVE_SELECTION_HIGHLIGHT_ALL: 'REMOVE_SELECTION_HIGHLIGHT_ALL',
    RENDER_TENSEC_STRIP: 'RENDER_TENSEC_STRIP',
    RENDER_SELECTION_STRIP: 'RENDER_SELECTION_STRIP',
    PREVENT_SECTION_STRIP: 'PREVENT_SECTION_STRIP',
    PREVENT_SHIFT_CLICK: 'PREVENT_SHIFT_CLICK',
  },
};

export const REPORT_REPRESENTATIVE_STRIP = {
  TYPE: {
    WHOLELINE: 'WHOLELINE',
    ONELINE: 'ONELINE',
    MULTILINE: {
      BEGIN: 'BEGIN',
      MID: 'MID',
      LAST: 'LAST',
    },
  },
};

export const ECG_CHART_UNIT = {
  MS_UNIT_PER_CHART_POINT: 4,
  FIVE_SEC: 5 * 1000,
  TEN_SEC: 10 * 1000,
  FIVE_SEC_WAVEFORM_IDX: (2500 / 10) * 5, // 10초에 2500개 waveform index
  TEN_SEC_WAVEFORM_IDX: 2500,
  THIRTY_SEC_WAVEFORM_IDX: 7500,
};

export const TEN_SEC_STRIP_EDIT = {
  BEAT_TYPE: ['N', 'S', 'V', 'Q'],
  BEAT_COLOR_TYPE: ['BLACK', 'SVE', 'VE', 'QUESTIONABLE'],
};

export const TEN_SEC_STRIP_DETAIL = {
  EDIT_MODE: {
    INIT: 'INIT',
    ADD_BEAT: 'ADD_BEAT',
    CHANGE_BEAT: 'CHANGE_BEAT', // show: beat option button
    CALIPER: 'CALIPER',
  },
  TAB: {
    ARRHYTHMIA_CONTEXTMENU: 'ARRHYTHMIA_CONTEXTMENU',
    EVENT_REVIEW: 'EVENT_REVIEW',
    HR_REVIEW: 'HR_REVIEW',
    BEAT_REVIEW: 'BEAT_REVIEW',
  },
};

export const TEN_SEC_SCRIPT_DETAIL = {
  HR: 'hr',
  BEAT_TYPE: 'beatType',
  WAVEFORM_INDEX: 'waveformIndex',
};

export const AMPLITUDE = {
  TEN_MV: 1,
  TWENTY_MV: 0.5,
};
