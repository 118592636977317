import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';
import {
  TEN_SEC_STRIP_DETAIL,
  TEN_SEC_STRIP_EDIT,
} from 'constant/ChartEditConst';
import {
  transformEctopicWithBeats,
  getSearchBeatsNEctopicListRangeAfterUpdateEvent,
} from 'util/TestResultDuckUtil';
import { handleNextSelectBpm } from 'util/HrReviewDuckUtil';
import {
  getBeatsNEctopicListRequested,
  getBeatsNEctopicListSucceed,
  selectRecordingTime,
} from './testResultDuck';

// Selector
const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;
const selectCurrPosition = (state) =>
  state.hrReviewReducer.sidePanel.currPosition;
const selectedBpm = (state) => state.hrReviewReducer.sidePanel.selectedBpm;
const selectHistogram = (state) =>
  state.hrReviewReducer.hrHistogram.data.histogram;
const selectBeatsData = (state) => state.hrReviewReducer.beats.data;
const selectBeatLabelButtonDataList = (state) =>
  state.hrReviewReducer.tenSecStripDetail.beatLabelButtonDataList;

// Actions
const SET_HR_REVIEW_TENSEC_STRIP_DETAIL =
  'memo-web/test-result/beat/SET_HR_REVIEW_TENSEC_STRIP_DETAIL';
// Get HrReview histogram data
const GET_HR_REVIEW_REQUESTED = 'memo-web/patch-ecgs/GET_HR_REVIEW_REQUESTED';
const GET_HR_REVIEW_SUCCEED = 'memo-web/patch-ecgs/GET_HR_REVIEW_SUCCEED';
const GET_HR_REVIEW_FAILED = 'memo-web/patch-ecgs/GET_HR_REVIEW_FAILED';
const SET_HR_HISTOGRAM_SELECT_BPM_INFO =
  'memo-web/patch-ecgs/SET_HR_HISTOGRAM_SELECT_BPM_INFO';
const SET_HR_REVIEW_CURRENT_POSITION =
  'memo-web/patch-ecgs/SET_HR_REVIEW_CURRENT_POSITION';

// hr-review histogram set-max, set-min
const LIMIT_REQUESTED = 'memo-web/patch-ecgs/LIMIT_REQUESTED';
const LIMIT_SUCCEED = 'memo-web/patch-ecgs/LIMIT_SUCCEED';
const LIMIT_FAILED = 'memo-web/patch-ecgs/LIMIT_FAILED';
// get beats
const GET_BEATS_REQUESTED_HR_REVIEW =
  'memo-web/patch-ecgs/GET_BEATS_REQUESTED_HR_REVIEW';
const GET_BEATS_SUCCEED_HR_REVIEW =
  'memo-web/patch-ecgs/GET_BEATS_SUCCEED_HR_REVIEW';
const GET_BEATS_FAILED_HR_REVIEW =
  'memo-web/patch-ecgs/GET_BEATS_FAILED_HR_REVIEW';

// post beats
const POST_BEATS_REQUESTED_HR_REVIEW =
  'memo-web/patch-ecgs/POST_BEATS_REQUESTED_HR_REVIEW';
const POST_BEATS_SUCCEED_HR_REVIEW =
  'memo-web/patch-ecgs/POST_BEATS_SUCCEED_HR_REVIEW';
const POST_BEATS_FAILED_HR_REVIEW =
  'memo-web/patch-ecgs/POST_BEATS_FAILED_HR_REVIEW';

// patch beats
const PATCH_BEATS_REQUESTED_HR_REVIEW =
  'memo-web/patch-ecgs/PATCH_BEATS_REQUESTED_HR_REVIEW';
const PATCH_BEATS_SUCCEED_HR_REVIEW =
  'memo-web/patch-ecgs/PATCH_BEATS_SUCCEED_HR_REVIEW';
const PATCH_BEATS_FAILED_HR_REVIEW =
  'memo-web/patch-ecgs/PATCH_BEATS_FAILED_HR_REVIEW';

// delete beats
const DELETE_BEATS_REQUESTED_HR_REVIEW =
  'memo-web/patch-ecgs/DELETE_BEATS_REQUESTED_HR_REVIEW';
const DELETE_BEATS_SUCCEED_HR_REVIEW =
  'memo-web/patch-ecgs/DELETE_BEATS_SUCCEED_HR_REVIEW';
const DELETE_BEATS_FAILED_HR_REVIEW =
  'memo-web/patch-ecgs/DELETE_BEATS_FAILED_HR_REVIEW';

// revert
const REVERT_REQUESTED = 'memo-web/patch-ecgs/REVERT_REQUESTED';
const REVERT_SUCCEED = 'memo-web/patch-ecgs/REVERT_SUCCEED';
const REVERT_FAILED = 'memo-web/patch-ecgs/REVERT_FAILED';

// etc function
// Reducer
// Action Creators
// Saga functions
// Saga

// Selector

// Actions

const initialState = {
  sidePanel: {
    selectedBpm: null, // 선택된 bpm
    maxPosition: null, // 선택된 Bpm의 Beat의 최대 인덱스(선택된 bpm의 beat수와 같음)
    currPosition: 1, // 선택된 Bpm의 Beat의 현재 인덱스
  },
  tenSecStripDetail: {
    onsetMs: undefined,
    terminationMs: undefined,
    onsetWaveformIdx: undefined,
    terminationWaveformIdx: undefined,
    hrAvg: undefined,
    ecgRaw: [],
    beatLabelButtonDataList: undefined,
    pending: false,
    error: null,
  },
  // HR histogram - data
  hrHistogram: {
    pending: false,
    data: {
      minHr: null,
      maxHr: null,
      avgHr: null,
      histogram: {},
      hrMinBound: null,
      hrMaxBound: null,
      isRevertable: false,
    },
    error: null,
  },
  // HR histogram - limit
  limit: {
    pending: false,
    error: null,
    isUpdate: false,
  },
  // HR histogram - revert
  revert: {
    revertTarget: null,
    afterRevertSelectedBpm: null,
    pending: false,
    error: null,
  },
  // Histogram beats waveformIndex List
  beats: {
    pending: false,
    data: {
      waveformIndex: [],
      beatType: [],
      hr: [],
      bpm: null,
      position: [],
    },
    error: null,
  },
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_HR_REVIEW_TENSEC_STRIP_DETAIL:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          ...action.data,
        },
      };
    // get hr review histogram
    case GET_HR_REVIEW_REQUESTED:
      return {
        ...state,
        hrHistogram: {
          ...state.hrHistogram,
          pending: true,
          error: null,
        },
      };
    case GET_HR_REVIEW_SUCCEED:
      const { result } = action.data;
      let { selectedBpm, currPosition } = state.sidePanel;

      if (selectedBpm === undefined) selectedBpm = result.hrMax;

      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          selectedBpm: selectedBpm,
          maxPosition: result.histogram[selectedBpm],
          currPosition: action.isInit ? 1 : currPosition,
        },
        hrHistogram: {
          ...state.hrHistogram,
          data: action.data.result,
          pending: false,
          error: null,
        },
      };
    case GET_HR_REVIEW_FAILED:
      return {
        ...state,
        hrHistogram: {
          ...state.hrHistogram,
          pending: false,
          error: action.error,
        },
      };
    case SET_HR_HISTOGRAM_SELECT_BPM_INFO:
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          selectedBpm: action.selectBpm,
          maxPosition: action.selectBeat,
          currPosition: action.currPosition ?? 1,
        },
      };
    case SET_HR_REVIEW_CURRENT_POSITION:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
        sidePanel: {
          ...state.sidePanel,
          currPosition: action.currPosition,
        },
      };
    // Histogram SET MAX, SET MIN
    case LIMIT_REQUESTED:
      return {
        ...state,
        limit: {
          ...state.limit,
          pending: true,
          error: null,
          isUpdate: false,
        },
      };
    case LIMIT_SUCCEED:
      // Limit 성공시 기존 histogram의 hrMaxBound와 hrMinBound값을 limit response의 hrMaxBound와 hrMinBound와 비교
      // 바뀐 값이 Revert할때 payload로 보낼 값이 된다(되돌리기 이므로 한단계씩 되돌려야하므로)
      const oldHistogram = state.hrHistogram.data;
      const newHrBounds = action.data.result;
      let revertTarget;
      let afterRevertSelectedBpm;

      if (oldHistogram.hrMaxBound !== newHrBounds.hrMaxBound) {
        revertTarget = {
          hrMaxBound: oldHistogram.hrMaxBound,
          hrMinBound: newHrBounds.hrMinBound,
        };
        afterRevertSelectedBpm = oldHistogram.hrMaxBound;
      }

      if (oldHistogram.hrMinBound !== newHrBounds.hrMinBound) {
        revertTarget = {
          hrMinBound: oldHistogram.hrMinBound,
          hrMaxBound: newHrBounds.hrMaxBound,
        };
        afterRevertSelectedBpm = oldHistogram.hrMinBound;
      }

      return {
        ...state,
        limit: {
          ...state.limit,
          pending: false,
          isUpdate: true,
        },
        revert: {
          ...state.revert,
          revertTarget: revertTarget,
          afterRevertSelectedBpm: afterRevertSelectedBpm,
        },
      };
    case LIMIT_FAILED:
      return {
        ...state,
        limit: {
          ...state.limit,
          pending: false,
          error: action.error,
        },
      };
    // Histogram Revert
    case REVERT_REQUESTED:
      return {
        ...state,
        revert: {
          ...state.revert,
          pending: true,
        },
      };
    case REVERT_SUCCEED:
      const revertSelectedBpm = state.revert.afterRevertSelectedBpm;
      return {
        ...state,
        revert: {
          ...state.revert,
          afterRevertSelectedBpm: null,
          pending: false,
        },
        sidePanel: {
          ...state.sidePanel,
          selectedBpm: revertSelectedBpm,
          maxPosition: state.hrHistogram.data.histogram[revertSelectedBpm],
          currPosition: 1,
        },
      };
    case REVERT_FAILED:
      return {
        ...state,
        revert: {
          ...state.revert,
          pending: false,
          error: action.error,
        },
      };
    // get Beats
    case GET_BEATS_REQUESTED_HR_REVIEW:
      return {
        ...state,
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case GET_BEATS_SUCCEED_HR_REVIEW:
      return {
        ...state,
        beats: {
          ...state.beats,
          data: action.data.result,
          pending: false,
          error: null,
        },
      };
    case GET_BEATS_FAILED_HR_REVIEW:
      return {
        ...state,
        beats: {
          ...state.beats,
          pending: false,
          error: action.error,
        },
      };
    // POST Beats - 비트추가
    case POST_BEATS_REQUESTED_HR_REVIEW:
      return {
        ...state,
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case POST_BEATS_SUCCEED_HR_REVIEW:
      let newBeatLabelButtonDataListAfterPostBeats;
      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        const editTargetWaveformIndex =
          apiResResult.waveformIndex[0] - onsetWaveformIdx;
        const editTargetBeatType = apiResResult.beatType[0];
        const nextIndexOfAddBeat = beatLabelButtonDataList.findIndex(
          (v) => v.xAxisPoint > editTargetWaveformIndex
        );
        beatLabelButtonDataList.splice(nextIndexOfAddBeat, 0, {
          xAxisPoint: editTargetWaveformIndex,
          beatType: editTargetBeatType,
          title: TEN_SEC_STRIP_EDIT.BEAT_TYPE[editTargetBeatType],
          color: TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[editTargetBeatType],
          isSelected: false,
        });
        newBeatLabelButtonDataListAfterPostBeats = beatLabelButtonDataList;
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: [
            ...newBeatLabelButtonDataListAfterPostBeats,
          ],
          pending: false,
          error: null,
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    case POST_BEATS_FAILED_HR_REVIEW:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    // PATCH Beats
    case PATCH_BEATS_REQUESTED_HR_REVIEW:
      return {
        ...state,
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case PATCH_BEATS_SUCCEED_HR_REVIEW:
      // 업데이트 성공시 get 재호출
      // 10s strip detail에서 업데이트 하는 경우만
      let newBeatLabelButtonDataListAfterPatchBeats;
      let updateBeatLabelButtonDataList = false;

      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;

        if (tabType === TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU) return;

        updateBeatLabelButtonDataList = true;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        for (let i in apiResResult.waveformIndex) {
          newBeatLabelButtonDataListAfterPatchBeats =
            beatLabelButtonDataList.map((v) => {
              if (
                v.xAxisPoint ===
                apiResResult.waveformIndex[i] - onsetWaveformIdx
              ) {
                v.isSelected = false;
                v.beatType = apiResResult.beatType[i];
                v.title = TEN_SEC_STRIP_EDIT.BEAT_TYPE[v.beatType];
                v.color = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[v.beatType];
                return v;
              }
              return v;
            });
        }
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: updateBeatLabelButtonDataList
            ? [...newBeatLabelButtonDataListAfterPatchBeats]
            : state.tenSecStripDetail.beatLabelButtonDataList,
          pending: false,
          error: null,
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    case PATCH_BEATS_FAILED_HR_REVIEW:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    // DELETE Beats
    case DELETE_BEATS_REQUESTED_HR_REVIEW:
      return {
        ...state,
        beats: {
          ...state.beats,
          pending: true,
          error: null,
        },
      };
    case DELETE_BEATS_SUCCEED_HR_REVIEW:
      let newBeatLabelButtonDataListAfterDeleteBeats;

      (function () {
        const { reqBody } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        const editTargetWaveformIndex =
          reqBody.waveformIndexes[0] - onsetWaveformIdx;
        const nextIndexOfAddBeat = beatLabelButtonDataList.findIndex(
          (v) => v.xAxisPoint > editTargetWaveformIndex
        );
        beatLabelButtonDataList.splice(nextIndexOfAddBeat - 1, 1);
        newBeatLabelButtonDataListAfterDeleteBeats = beatLabelButtonDataList;
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: [
            ...newBeatLabelButtonDataListAfterDeleteBeats,
          ],
          pending: false,
          error: null,
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    case DELETE_BEATS_FAILED_HR_REVIEW:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
        beats: {
          ...state.beats,
          pending: false,
          error: null,
        },
      };
    default:
      return state;
  }
}

// Action Creators
function setBeatTenSecStripDetail(data) {
  return { type: SET_HR_REVIEW_TENSEC_STRIP_DETAIL, data };
}
// Get HR Histogram
export function getHrReviewRequested(isInit) {
  return { type: GET_HR_REVIEW_REQUESTED, isInit };
}
export function getHrReviewSucceed(data, isInit) {
  return { type: GET_HR_REVIEW_SUCCEED, data, isInit };
}
function getHrReviewFailed(error) {
  return { type: GET_HR_REVIEW_FAILED, error };
}

// hr review histogram set max/min limit
export function limitRequested(ecgTestId, reqBody) {
  return {
    type: LIMIT_REQUESTED,
    ecgTestId,
    reqBody,
  };
}
export function limitSucceed(data) {
  return {
    type: LIMIT_SUCCEED,
    data,
  };
}
export function limitFailed(error) {
  return {
    type: LIMIT_FAILED,
    error,
  };
}

// hr review histogram max/min revert
export function revertRequested(reqBody) {
  return {
    type: REVERT_REQUESTED,
    reqBody,
  };
}
export function revertSucceed(data) {
  return {
    type: REVERT_SUCCEED,
    data,
  };
}
export function revertFailed(error) {
  return {
    type: REVERT_FAILED,
    error,
  };
}

// get beats
export function getBeatsRequested(bpm, suffix) {
  return {
    type: GET_BEATS_REQUESTED_HR_REVIEW,
    bpm,
    suffix,
  };
}
export function getBeatsSucceed(data) {
  return {
    type: GET_BEATS_SUCCEED_HR_REVIEW,
    data,
  };
}
export function getBeatsFailed(error) {
  return {
    type: GET_BEATS_FAILED_HR_REVIEW,
    error,
  };
}

// post beats
export function postBeatsRequestedHrReview(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType,
  selectedBeatWaveformIndex
) {
  return {
    type: POST_BEATS_REQUESTED_HR_REVIEW,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
    selectedBeatWaveformIndex,
  };
}
export function postBeatsSucceedHrReview(data) {
  return {
    type: POST_BEATS_SUCCEED_HR_REVIEW,
    data,
  };
}
export function postBeatsFailedHrReview(error) {
  return {
    type: POST_BEATS_FAILED_HR_REVIEW,
    error,
  };
}

// patch beats
export function patchBeatsRequestedHrReview(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType,
  selectedBeatWaveformIndex
) {
  return {
    type: PATCH_BEATS_REQUESTED_HR_REVIEW,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
    selectedBeatWaveformIndex,
  };
}
export function patchBeatsSucceedHrReview(data, tabType) {
  return {
    type: PATCH_BEATS_SUCCEED_HR_REVIEW,
    data,
    tabType,
  };
}
export function patchBeatsFailedHrReview(error) {
  return {
    type: PATCH_BEATS_FAILED_HR_REVIEW,
    error,
  };
}

// delete beats
export function deleteBeatsRequestedHrReview(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType,
  selectedBeatWaveformIndex
) {
  return {
    type: DELETE_BEATS_REQUESTED_HR_REVIEW,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
    selectedBeatWaveformIndex,
  };
}
export function deleteBeatsSucceedHrReview(reqBody) {
  return {
    type: DELETE_BEATS_SUCCEED_HR_REVIEW,
    reqBody,
  };
}
export function deleteBeatsFailedHrReview(error) {
  return {
    type: DELETE_BEATS_FAILED_HR_REVIEW,
    error,
  };
}
export function setSelectedBpmInfo(selectBpm, selectBeat, currPosition) {
  return {
    type: SET_HR_HISTOGRAM_SELECT_BPM_INFO,
    selectBpm,
    selectBeat,
    currPosition,
  };
}
export function setHrReviewCurrentPosition(currPosition) {
  return { type: SET_HR_REVIEW_CURRENT_POSITION, currPosition };
}

// Saga functions
function* _getHrReview(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const apiEndpoint = ApiManager.getHrHistogram;
    const { data } = yield call(apiEndpoint, {
      ecgTestId,
    });

    yield put(getHrReviewSucceed(data, action.isInit));
  } catch (error) {
    yield put(getHrReviewFailed(error));
  }
}

function* _limit(action) {
  try {
    const { data } = yield call(
      ApiManager.limit,
      action.ecgTestId,
      action.reqBody
    );

    yield put(limitSucceed(data));
  } catch (error) {
    yield put(limitFailed(error));
  }
}

function* _revert(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { data, status } = yield call(
      ApiManager.revert,
      ecgTestId,
      action.reqBody
    );
    // 성공시 getHistogram
    if (status === 200) {
      // 해당 req 성공 이후 revert한 결과에 따라서 selectedBpm을 변경해준다
      yield put(getHrReviewRequested());
    }

    yield put(revertSucceed(data));
  } catch (error) {
    yield put(revertFailed(error));
  }
}

function* getTenSecStripDetail(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const _selectBeatsData = yield select(selectBeatsData);
    const { recordingStartMs } = yield select(selectRecordingTime);
    const selectedIndex =
      _selectBeatsData.waveformIndex[action.currPosition - 1];
    const { searchOnsetRequest, searchTerminationRequest } =
      getSearchBeatsNEctopicListRangeAfterUpdateEvent(
        selectedIndex,
        selectedIndex + 2500
      );

    const leadOffList = yield select(
      ({ testResultReducer: state }) => state.timeEventsList.leadOff
    );
    const {
      data: { results },
    } = yield call(ApiManager.getRawEcg, {
      ecgTestId,
      onsetMs: recordingStartMs + searchOnsetRequest * 4,
      terminationMs: recordingStartMs + searchTerminationRequest * 4,
      withBeat: true,
    });

    const rawEcgData = () => {
      const onsetWaveformIndex = results[0].onsetWaveformIndex;

      if (results.length === 1) {
        // Hr Review 검사 시작지점 검증
        if (onsetWaveformIndex <= 1250) {
          return [
            ...Array.from(
              { length: 1250 - selectedIndex - onsetWaveformIndex },
              () => 0
            ),
          ].concat(results[0].ecgData);
        }
        return results[0].ecgData.slice(
          selectedIndex - onsetWaveformIndex - 1250,
          selectedIndex - onsetWaveformIndex + 1250
        );
      } else {
        const entireEcgDataArray = results[0].ecgData.concat(
          results[1].ecgData
        );
        return entireEcgDataArray.slice(
          selectedIndex - onsetWaveformIndex - 1250,
          selectedIndex - onsetWaveformIndex + 1250
        );
      }
    };

    const beats = {
      waveformIndex: results[0].beats.waveformIndex.concat(
        results[1]?.beats.waveformIndex || []
      ),
      beatType: results[0].beats.beatType.concat(
        results[1]?.beats.beatType || []
      ),
      hr: results[0].beats.hr.concat(results[1]?.beats.hr || []),
    };

    const tenSecStripDataHandler = () => {
      const onsetWaveformIndex = selectedIndex - 1250;
      const terminationWaveformIndex = selectedIndex + 1250;
      const onsetMs = recordingStartMs + onsetWaveformIndex * 4;
      const terminationMs = onsetMs + 10 * 1000;
      const { hr, waveformIndex } = beats;
      const ecgRaw = rawEcgData();
      const hrArray = hr
        .slice(
          waveformIndex.findIndex((v) => v >= onsetWaveformIndex),
          waveformIndex.findLastIndex((v) => v <= terminationWaveformIndex) + 1
        )
        .filter(Boolean);
      const sumHr = hrArray.reduce((acc, cur) => acc + cur, 0);
      const tenSecStripHrAvg = Math.floor((sumHr / hrArray.length) * 10) / 10;
      const beatLabelButtonDataList = [];
      const beatType = TEN_SEC_STRIP_EDIT.BEAT_TYPE;
      const beatColorType = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE;
      const waveformIndexList = waveformIndex
        .slice(
          waveformIndex.findIndex((v) => v >= onsetWaveformIndex),
          waveformIndex.findLastIndex((v) => v <= terminationWaveformIndex) + 1
        )
        .map((waveformIdx) => waveformIdx - onsetWaveformIndex);
      const beatTypeList = beats.beatType.slice(
        waveformIndex.findIndex((v) => v >= onsetWaveformIndex),
        waveformIndex.findLastIndex((v) => v <= terminationWaveformIndex) + 1
      );
      for (let index in waveformIndexList) {
        beatLabelButtonDataList.push({
          xAxisPoint: waveformIndexList[index],
          isSelected:
            waveformIndexList[index] ===
            beats.waveformIndex[0] - onsetWaveformIndex,
          beatType: beatTypeList[index],
          title: beatType[beatTypeList[index]],
          color: beatColorType[beatTypeList[index]],
          isEventReview: '',
        });
      }

      return {
        onsetMs: onsetMs,
        terminationMs: terminationMs,
        onsetWaveformIdx: onsetWaveformIndex,
        terminationWaveformIdx: terminationWaveformIndex,
        hrAvg: tenSecStripHrAvg,
        ecgRaw: ecgRaw,
        beatLabelButtonDataList,
      };
    };

    yield put(setBeatTenSecStripDetail(tenSecStripDataHandler()));
    yield put(
      getBeatsNEctopicListSucceed(
        transformEctopicWithBeats(beats, leadOffList, recordingStartMs)
      )
    );
  } catch (error) {
    console.error(error);
  }
}

function* _getBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const _selectCurrPosition = yield select(selectCurrPosition);

    const { bpm, suffix } = action;

    const apiEndPoint = ApiManager.getBeats;
    const { data } = yield call(apiEndPoint, {
      ecgTestId,
      suffix,
      bpm: bpm,
    });

    yield put(getBeatsSucceed(data));
    yield put(setHrReviewCurrentPosition(_selectCurrPosition));
  } catch (error) {
    yield put(getBeatsFailed(error));
  }
}

function* _postBeats(action) {
  try {
    const _previousSelectedBpm = yield select(selectedBpm);
    const _selectHistogram = yield select(selectHistogram);
    const ecgTestId = yield select(selectEcgTestId);
    const _selectBeatLabelButtonDataList = yield select(
      selectBeatLabelButtonDataList
    );
    const _selectCurrPosition = yield select(selectCurrPosition);

    const { selectedBeatWaveformIndex } = action;
    const { nextSelectBpm, nextPosition } = handleNextSelectBpm({
      _selectHistogram,
      _previousSelectedBpm,
      _selectBeatLabelButtonDataList,
      selectedBeatWaveformIndex,
      _selectCurrPosition,
    });

    const { data } = yield call(
      ApiManager.postBeats,
      ecgTestId,
      action.suffix,
      action.reqBody
    );

    yield put(postBeatsSucceedHrReview(data));
    yield put(getHrReviewRequested());

    // 선택된 bpm의 maxPosition이 1이고, 비트업데이트로 인해 선택된 bpm이 변경되면 다음 bpm을 조회
    if (nextSelectBpm === _previousSelectedBpm) {
      if (nextPosition !== _selectCurrPosition) {
        yield put(setHrReviewCurrentPosition(nextPosition));
      }
      yield put(getBeatsRequested(nextSelectBpm, 'filter/bpm'));
    } else {
      yield put(
        setSelectedBpmInfo(
          Number(nextSelectBpm),
          _selectHistogram[nextSelectBpm],
          1
        )
      );
    }
  } catch (error) {
    yield put(postBeatsFailedHrReview(error));
  }
}

function* _patchBeats(action) {
  try {
    const _previousSelectedBpm = yield select(selectedBpm);
    const _selectHistogram = yield select(selectHistogram);
    const ecgTestId = yield select(selectEcgTestId);
    const _selectBeatLabelButtonDataList = yield select(
      selectBeatLabelButtonDataList
    );
    const _selectCurrPosition = yield select(selectCurrPosition);

    const { reqBody, suffix, tabType, selectedBeatWaveformIndex } = action;
    const updateBeatType = reqBody?.beatType;
    const { nextSelectBpm, nextPosition } = handleNextSelectBpm({
      _selectHistogram,
      _previousSelectedBpm,
      _selectBeatLabelButtonDataList,
      selectedBeatWaveformIndex,
      _selectCurrPosition,
      updateBeatType,
    });

    const { data } = yield call(
      ApiManager.patchBeats,
      ecgTestId,
      suffix,
      reqBody
    );

    yield put(patchBeatsSucceedHrReview(data, tabType));
    yield put(getHrReviewRequested());

    // 선택된 bpm의 maxPosition이 1이고, 비트업데이트로 인해 선택된 bpm이 변경되면 다음 bpm을 조회
    if (nextSelectBpm === _previousSelectedBpm) {
      if (nextPosition !== _selectCurrPosition) {
        yield put(setHrReviewCurrentPosition(nextPosition));
      }
      yield put(getBeatsRequested(nextSelectBpm, 'filter/bpm'));
    } else {
      yield put(
        setSelectedBpmInfo(
          Number(nextSelectBpm),
          _selectHistogram[nextSelectBpm]
        )
      );
    }
  } catch (error) {
    console.log('error: ', error);
    yield put(patchBeatsFailedHrReview(error));
  }
}

function* _deleteBeats(action) {
  try {
    const _previousSelectedBpm = yield select(selectedBpm);
    const _selectHistogram = yield select(selectHistogram);
    const ecgTestId = yield select(selectEcgTestId);
    const _selectBeatLabelButtonDataList = yield select(
      selectBeatLabelButtonDataList
    );
    const _selectCurrPosition = yield select(selectCurrPosition);

    const { reqBody, suffix, selectedBeatWaveformIndex } = action;
    const { nextSelectBpm, nextPosition } = handleNextSelectBpm({
      _selectHistogram,
      _previousSelectedBpm,
      _selectBeatLabelButtonDataList,
      selectedBeatWaveformIndex,
      _selectCurrPosition,
    });
    const { status } = yield call(
      ApiManager.deleteBeats,
      ecgTestId,
      suffix,
      reqBody
    );
    if (status !== 204) throw Error;

    yield put(deleteBeatsSucceedHrReview(reqBody));
    yield put(getHrReviewRequested());

    // 선택된 bpm의 maxPosition이 1이고, 비트업데이트로 인해 선택된 bpm이 변경되면 다음 bpm을 조회
    if (nextSelectBpm === _previousSelectedBpm) {
      if (nextPosition !== _selectCurrPosition) {
        yield put(setHrReviewCurrentPosition(nextPosition));
      }
      yield put(getBeatsRequested(nextSelectBpm, 'filter/bpm'));
    } else {
      yield put(
        setSelectedBpmInfo(
          Number(nextSelectBpm),
          _selectHistogram[nextSelectBpm]
        )
      );
    }
  } catch (error) {
    yield put(deleteBeatsFailedHrReview(error));
  }
}

// Saga
export function* saga() {
  // :: TAB :: hr review tab
  yield takeLatest(GET_HR_REVIEW_REQUESTED, _getHrReview);
  yield takeLatest(LIMIT_REQUESTED, _limit);
  yield takeLatest(REVERT_REQUESTED, _revert);
  yield takeLatest(SET_HR_REVIEW_CURRENT_POSITION, getTenSecStripDetail);
  yield takeLatest(GET_BEATS_REQUESTED_HR_REVIEW, _getBeats);

  // :: 10s strip detail - beat edit ::
  yield takeEvery(POST_BEATS_REQUESTED_HR_REVIEW, _postBeats); // create beat
  yield takeEvery(PATCH_BEATS_REQUESTED_HR_REVIEW, _patchBeats); // modify beat
  yield takeEvery(DELETE_BEATS_REQUESTED_HR_REVIEW, _deleteBeats); // delete beat
}
