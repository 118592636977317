import React from 'react';
import AlertDialog from './AlertDialog';

function AlertExpireDialog(props) {
  const {
    // Redux Dispatches
    logout,
    // Component Properties
    open,
    callback,
    onClose,
  } = props;

  return (
    <AlertDialog
      open={open}
      params={{
        message:
          '다른 브라우저에서 동일한 아이디로 접속하여 자동 로그아웃 되었습니다.',
        confirmButtonText: '확인',
      }}
      callback={logout}
      onClose={onClose}
      zIndex={13}
    />
  );
}

export default AlertExpireDialog;
