export const handleNextSelectBpm = ({
  _selectHistogram,
  _previousSelectedBpm,
  _selectBeatLabelButtonDataList,
  selectedBeatWaveformIndex,
  _selectCurrPosition,
  updateBeatType,
}) => {
  const indexOfPointCenter = _selectBeatLabelButtonDataList.findIndex(
    (data) => data.xAxisPoint === 1250
  );
  const previousPointOfCenterPoint =
    _selectBeatLabelButtonDataList[indexOfPointCenter - 1]?.xAxisPoint || 0;
  const isSelectedWaveformIndexBetweenPreviousPointAndCenterPoint =
    previousPointOfCenterPoint <= selectedBeatWaveformIndex &&
    selectedBeatWaveformIndex <= 1250;

  let nextSelectBpm = _previousSelectedBpm;
  let nextPosition = _selectCurrPosition;

  // 마지막 포지션에서 bpm에 변경이 생긴 경우
  if (
    _selectCurrPosition !== 1 &&
    _selectHistogram[_previousSelectedBpm] === _selectCurrPosition &&
    isSelectedWaveformIndexBetweenPreviousPointAndCenterPoint
  ) {
    nextPosition = _selectCurrPosition - 1;
    return { nextSelectBpm, nextPosition };
  }

  // 포지션이 1 of 1 이고, patch에 의해 가운데 bpm에 변경이 생긴 경우
  if (
    _selectHistogram[_previousSelectedBpm] === 1 &&
    isSelectedWaveformIndexBetweenPreviousPointAndCenterPoint &&
    updateBeatType != null &&
    updateBeatType !== 3
  ) {
    return { nextSelectBpm, nextPosition };
  }

  // 포지션이 1 of 1 이고, 가운데 bpm에 변경이 생길 경우
  if (
    _selectHistogram[_previousSelectedBpm] === 1 &&
    isSelectedWaveformIndexBetweenPreviousPointAndCenterPoint
  ) {
    const histogramArray = Object.keys(_selectHistogram).filter(
      (bpm) => _selectHistogram[bpm]
    );
    const currentBpmIndex = histogramArray.findIndex(
      (bpm) => Number(bpm) === _previousSelectedBpm
    );

    nextSelectBpm =
      currentBpmIndex === histogramArray.length - 1
        ? Number(histogramArray[currentBpmIndex - 1])
        : Number(histogramArray[currentBpmIndex + 1]);
    return { nextSelectBpm, nextPosition };
  }

  return { nextSelectBpm, nextPosition };
};
