import { all } from 'redux-saga/effects';
// Sagas
import { saga as authSaga } from './duck/authDuck';
import { saga as medicalStaffsSaga } from './duck/medicalStaffsDuck';
import { saga as ecgTestSaga } from './duck/ecgTestsDuck';
import { saga as ecgTotalSaga } from './duck/ecgTotalDuck';
import { saga as eventSaga } from './duck/eventDuck';
import { saga as reportSaga } from './duck/reportDuck';
import { saga as patchEcgsSaga } from './duck/patchEcgsDuck';
import { saga as testResultSaga } from './duck/testResultDuck';
import { saga as beatReviewSaga } from './duck/beatReviewDuck';
import { saga as hrReviewSaga } from './duck/hrReviewDuck';

export default function* rootSaga() {
  yield all([
    authSaga(),
    medicalStaffsSaga(),
    ecgTestSaga(),
    ecgTotalSaga(),
    eventSaga(),
    reportSaga(),
    patchEcgsSaga(),
    testResultSaga(),
    beatReviewSaga(),
    hrReviewSaga(),
  ]);
}
