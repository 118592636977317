export const events = {
  data: [],

  subscribe: function (data) {
    this.data.push(data);
  },

  publish: function (callback, type) {
    callback(() => {
      this.data.forEach((v) => v());
    });
    this.data = [];
  },
};
