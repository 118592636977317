import { connect } from 'react-redux';
import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import { authLogoutRequested } from 'redux/duck/authDuck';
import Header from 'component/base/Header';

const mapStateToProps = (state, ownProps) => {
  return {
    authState: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // Dialog
    showDialog: (dialogKey, params, callback) => {
      dispatch(showDialog(dialogKey, params, callback));
    },
    hideDialog: (dialogKey) => {
      dispatch(hideDialog(dialogKey));
    },
    // Auth
    logout: () => {
      dispatch(authLogoutRequested());
    },
  };
};

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderContainer;
