const _blackBox = {
  showExpireAlert: null,
  showNetworkAlert: null,
  callback: null,
};

const TrickUtil = {
  showExpireAlert: () => {
    if (typeof _blackBox.showExpireAlert === 'function')
      return _blackBox.showExpireAlert();
  },
  setExpireAlert: (dispatchFunction) =>
    (_blackBox.showExpireAlert = dispatchFunction),
  showNetworkAlert: (errorInfo) => {
    if (typeof _blackBox.showNetworkAlert === 'function')
      return _blackBox.showNetworkAlert(errorInfo);
  },
  setNetworkAlert: (dispatchFunction) =>
    (_blackBox.showNetworkAlert = dispatchFunction),
  callback: () => {
    if (typeof _blackBox.callback === 'function') return _blackBox.callback();
  },
  setCallback: (dispatchFunction) => (_blackBox.callback = dispatchFunction),
};

export default TrickUtil;
