/**
 * ❗️❗️❗️
 * *주의*
 * ❗️❗️❗️
 *
 * 하기 코드는 파트너 웹과 병원 웹 간 차이가 있습니다.
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';
import LocalStorageManager from 'manager/LocalStorageManager';
import LocalStorageKey from 'constant/LocalStorageKey';

// Actions
// CheckEmail
const CHECK_EMAIL_REQUESTED = 'memo-web/auth/CHECK_EMAIL_REQUESTED';
const CHECK_EMAIL_SUCCEED = 'memo-web/auth/CHECK_EMAIL_SUCCEED';
const CHECK_EMAIL_FAILED = 'memo-web/auth/CHECK_EMAIL_FAILED';
// ResetPassword
const RESET_PASSWORD_REQUESTED = 'memo-web/auth/RESET_PASSWORD_REQUESTED';
const RESET_PASSWORD_SUCCEED = 'memo-web/auth/RESET_PASSWORD_SUCCEED';
const RESET_PASSWORD_FAILED = 'memo-web/auth/RESET_PASSWORD_FAILED';
// ResetPasswordByEmail
const RESET_PASSWORD_BY_EMAIL_REQUESTED =
  'memo-web/auth/RESET_PASSWORD_BY_EMAIL_REQUESTED';
const RESET_PASSWORD_BY_EMAIL_SUCCEED =
  'memo-web/auth/RESET_PASSWORD_BY_EMAIL_SUCCEED';
const RESET_PASSWORD_BY_EMAIL_FAILED =
  'memo-web/auth/RESET_PASSWORD_BY_EMAIL_FAILED';
// CompleteResetPasswordByEmail
const COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED =
  'memo-web/auth/COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED';
const COMPLETE_RESET_PASSWORD_BY_EMAIL_SUCCEED =
  'memo-web/auth/COMPLETE_RESET_PASSWORD_BY_EMAIL_SUCCEED';
const COMPLETE_RESET_PASSWORD_BY_EMAIL_FAILED =
  'memo-web/auth/COMPLETE_RESET_PASSWORD_BY_EMAIL_FAILED';
// ResetPasswordNextTime
const RESET_PASSWORD_NEXT_TIME_REQUESTED =
  'memo-web/auth/RESET_PASSWORD_NEXT_TIME_REQUESTED';
const RESET_PASSWORD_NEXT_TIME_SUCCEED =
  'memo-web/auth/RESET_PASSWORD_NEXT_TIME_SUCCEED';
const RESET_PASSWORD_NEXT_TIME_FAILED =
  'memo-web/auth/RESET_PASSWORD_NEXT_TIME_FAILED';
// ResetPasswordWithOld
const RESET_PASSWORD_WITH_OLD_REQUESTED =
  'memo-web/auth/RESET_PASSWORD_WITH_OLD_REQUESTED';
const RESET_PASSWORD_WITH_OLD_SUCCEED =
  'memo-web/auth/RESET_PASSWORD_WITH_OLD_SUCCEED';
const RESET_PASSWORD_WITH_OLD_FAILED =
  'memo-web/auth/RESET_PASSWORD_WITH_OLD_FAILED';
// Login
const LOGIN_REQUESTED = 'memo-web/auth/LOGIN_REQUESTED';
const LOGIN_SUCCEED = 'memo-web/auth/LOGIN_SUCCEED';
const LOGIN_FAILED = 'memo-web/auth/LOGIN_FAILED';
// Logout
const LOGOUT_REQUESTED = 'memo-web/auth/LOGOUT_REQUESTED';
const LOGOUT_SUCCEED = 'memo-web/auth/LOGOUT_SUCCEED';
const LOGOUT_FAILED = 'memo-web/auth/LOGOUT_FAILED';
// ReadUserConsent
const READ_USER_CONSENT_REQUESTED = 'memo-web/auth/READ_USER_CONSENT_REQUESTED';
const READ_USER_CONSENT_SUCCEED = 'memo-web/auth/READ_USER_CONSENT_SUCCEED';
const READ_USER_CONSENT_FAILED = 'memo-web/auth/READ_USER_CONSENT_FAILED';
// WriteUserConsent
const WRITE_USER_CONSENT_REQUESTED =
  'memo-web/auth/WRITE_USER_CONSENT_REQUESTED';
const WRITE_USER_CONSENT_SUCCEED = 'memo-web/auth/WRITE_USER_CONSENT_SUCCEED';
const WRITE_USER_CONSENT_FAILED = 'memo-web/auth/WRITE_USER_CONSENT_FAILED';
// ReadMyInformation
const READ_MY_INFORMATION_REQUESTED =
  'memo-web/auth/READ_MY_INFORMATION_REQUESTED';
const READ_MY_INFORMATION_SUCCEED = 'memo-web/auth/READ_MY_INFORMATION_SUCCEED';
const READ_MY_INFORMATION_FAILED = 'memo-web/auth/READ_MY_INFORMATION_FAILED';

// Reducer
const initialState = {
  pending: false,
  data: {},
  me: null,
  isLoggedIn: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    /*
        CheckEmail
        ResetPassword
        ResetPasswordByEmail
        CompleteResetPasswordByEmail
        ResetPasswordNextTime
        ResetPasswordWithOld 
        WriteUserConsent
        */
    case CHECK_EMAIL_REQUESTED:
    case RESET_PASSWORD_REQUESTED:
    case RESET_PASSWORD_BY_EMAIL_REQUESTED:
    case COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED:
    case RESET_PASSWORD_NEXT_TIME_REQUESTED:
    case RESET_PASSWORD_WITH_OLD_REQUESTED:
    case WRITE_USER_CONSENT_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case CHECK_EMAIL_SUCCEED:
    case RESET_PASSWORD_SUCCEED:
    case RESET_PASSWORD_BY_EMAIL_SUCCEED:
    case COMPLETE_RESET_PASSWORD_BY_EMAIL_SUCCEED:
    case RESET_PASSWORD_NEXT_TIME_SUCCEED:
    case RESET_PASSWORD_WITH_OLD_SUCCEED:
    case WRITE_USER_CONSENT_SUCCEED:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case CHECK_EMAIL_FAILED:
    case RESET_PASSWORD_FAILED:
    case RESET_PASSWORD_BY_EMAIL_FAILED:
    case COMPLETE_RESET_PASSWORD_BY_EMAIL_FAILED:
    case RESET_PASSWORD_NEXT_TIME_FAILED:
    case RESET_PASSWORD_WITH_OLD_FAILED:
    case WRITE_USER_CONSENT_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    // Login
    case LOGIN_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    // case LOGIN_SUCCEED:
    //     return {
    //         ...state,
    //         pending: false,
    //         data: action.data,
    //         me: action.me,
    //         isLoggedIn: true,
    //     };
    case LOGIN_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    // ReadMyInformation
    case READ_MY_INFORMATION_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case READ_MY_INFORMATION_SUCCEED:
      return {
        ...state,
        pending: false,
        data: action.data,
        me: action.me,
        isLoggedIn: true,
      };
    case READ_MY_INFORMATION_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    // Logout
    case LOGOUT_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case LOGOUT_SUCCEED:
      return {
        ...state,
        pending: false,
        data: action.data,
        me: null,
        isLoggedIn: false,
      };
    case LOGOUT_FAILED:
      return {
        ...state,
        pending: false,
        isLoggedIn: false,
        error: action.error,
      };
    // ReadUserConsent
    case READ_USER_CONSENT_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case READ_USER_CONSENT_SUCCEED:
      return {
        ...state,
        pending: false,
        me: {
          ...state.me,
          ...action.data,
        },
      };
    case READ_USER_CONSENT_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Action Creators
// CheckEmail
export function authCheckEmailRequested(email) {
  return { type: CHECK_EMAIL_REQUESTED, email };
}
function authCheckEmailSucceed(data) {
  return { type: CHECK_EMAIL_SUCCEED, data };
}
function authCheckEmailFailed(error) {
  return { type: CHECK_EMAIL_FAILED, error };
}
// ResetPassword
export function authResetPasswordRequested(newPassword) {
  return { type: RESET_PASSWORD_REQUESTED, newPassword };
}
function authResetPasswordSucceed(data) {
  return { type: RESET_PASSWORD_SUCCEED, data };
}
function authResetPasswordFailed(error) {
  return { type: RESET_PASSWORD_FAILED, error };
}
// ResetPasswordByEmail
function authResetPasswordByEmailRequested(email, code, originAddr) {
  return {
    type: RESET_PASSWORD_BY_EMAIL_REQUESTED,
    email,
    code,
  };
}
function authResetPasswordByEmailSucceed(data) {
  return { type: RESET_PASSWORD_BY_EMAIL_SUCCEED, data };
}
function authResetPasswordByEmailFailed(error) {
  return { type: RESET_PASSWORD_BY_EMAIL_FAILED, error };
}
// CompleteResetPasswordByEmail
export function authCompleteResetPasswordByEmailRequested(
  password,
  token,
  uidb64
) {
  return {
    type: COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED,
    password,
    token,
    uidb64,
  };
}
function authCompleteResetPasswordByEmailSucceed(data) {
  return { type: COMPLETE_RESET_PASSWORD_BY_EMAIL_SUCCEED, data };
}
function authCompleteResetPasswordByEmailFailed(error) {
  return { type: COMPLETE_RESET_PASSWORD_BY_EMAIL_FAILED, error };
}
// ResetPasswordNextTime
export function authResetPasswordNextTimeRequested() {
  return { type: RESET_PASSWORD_NEXT_TIME_REQUESTED };
}
function authResetPasswordNextTimeSucceed(data) {
  return { type: RESET_PASSWORD_NEXT_TIME_SUCCEED, data };
}
function authResetPasswordNextTimeFailed(error) {
  return { type: RESET_PASSWORD_NEXT_TIME_FAILED, error };
}
// ResetPasswordWithOld
export function authResetPasswordWithOldRequested(oldPassword, newPassword) {
  return {
    type: RESET_PASSWORD_WITH_OLD_REQUESTED,
    oldPassword,
    newPassword,
  };
}
function authResetPasswordWithOldSucceed(data) {
  return { type: RESET_PASSWORD_WITH_OLD_SUCCEED, data };
}
function authResetPasswordWithOldFailed(error) {
  return { type: RESET_PASSWORD_WITH_OLD_FAILED, error };
}
// Login
export function authLoginRequested(email, password) {
  return {
    type: LOGIN_REQUESTED,
    email,
    password,
  };
}
function authLoginSucceed(data, me) {
  return { type: LOGIN_SUCCEED, data, me };
}
function authLoginFailed(error) {
  return { type: LOGIN_FAILED, error };
}
// ReadMyInformation
export function authReadMyInformationRequested(data) {
  return { type: READ_MY_INFORMATION_REQUESTED, data };
}
function authReadMyInformationSucceed(me, data) {
  return { type: READ_MY_INFORMATION_SUCCEED, me, data };
}
function authReadMyInformationFailed(error) {
  return { type: READ_MY_INFORMATION_FAILED, error };
}
// Logout
export function authLogoutRequested() {
  return { type: LOGOUT_REQUESTED };
}
function authLogoutSucceed(data) {
  return { type: LOGOUT_SUCCEED, data };
}
function authLogoutFailed(error) {
  return { type: LOGOUT_FAILED, error };
}
// ReadUserConsent
export function authReadUserConsentRequested() {
  return { type: READ_USER_CONSENT_REQUESTED };
}
function authReadUserConsentSucceed(data) {
  return { type: READ_USER_CONSENT_SUCCEED, data };
}
function authReadUserConsentFailed(error) {
  return { type: READ_USER_CONSENT_FAILED, error };
}
// WriteUserConsent
export function authWriteUserConsentRequested(isConfirmed) {
  return { type: WRITE_USER_CONSENT_REQUESTED, isConfirmed };
}
function authWriteUserConsentSucceed(data) {
  return { type: WRITE_USER_CONSENT_SUCCEED, data };
}
function authWriteUserConsentFailed(error) {
  return { type: WRITE_USER_CONSENT_FAILED, error };
}

// Sagas
/**
 *
 * @param {*} action
 * @returns
 */
function* checkEmail(action) {
  try {
    const { data } = yield call(ApiManager.checkEmail, action.email);
    const { result } = data;

    // XXX: 만약 서브 계정 등록 시 중복 이메일 체크로 해당 API 활용 되면 로직 수정 필요!!!
    // 이어서 RESET_PASSWORD_BY_EMAIL_REQUESTED 액션 실행 후 리턴
    if (result.code === 1000 || result.code === 1002 || result.code === 1003) {
      // 1000: 일반 비밀번호 찾기, 1002: 휴면 계정, 1003: 비밀번호 최초설정
      yield put(authResetPasswordByEmailRequested(action.email, result.code));
      return;
    }
    // code 1004: 존재하지 않는 계정; 미등록 이메일
    yield put(authCheckEmailSucceed(result));
  } catch (error) {
    yield put(
      authCheckEmailFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

/**
 * Authorized
 * 1.5 휴면 계정 복구
 * @param {*} action
 * @returns
 */
function* resetPassword(action) {
  try {
    const { data } = yield call(ApiManager.resetPassword, action.newPassword);
    const { result } = data;

    yield put(authResetPasswordSucceed(result));
  } catch (error) {
    yield put(
      authResetPasswordFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

/**
 * 전달 받은 이메일로 비밀번호 초기화 링크 전송 요청
 * 1.3.1 비밀번호 찾기
 * @param {*} action
 * @returns
 */
function* resetPasswordByEmail(action) {
  try {
    let redirectPath;
    let changeType;
    switch (action.code) {
      case 1000:
        // 일반 비밀번호 찾기
        redirectPath = `${window.origin}/reset-password/forgot`;
        changeType = 'forgot';
        break;
      case 1002:
        // 휴면 계정
        redirectPath = `${window.origin}/reset-password/release-dormant`;
        changeType = 'release-dormant';
        break;
      case 1003:
        // 비밀번호 최초 설정
        redirectPath = `${window.origin}/reset-password/new`;
        changeType = 'new';
        break;
      default:
        // FIXME: 자세한 오류 처리
        yield put(authResetPasswordByEmailFailed({ status: 500, message: '' }));
        return;
    }
    const { data } = yield call(
      ApiManager.resetPasswordByEmail,
      action.email,
      redirectPath,
      changeType
    );

    const { result } = data;

    yield put(authResetPasswordByEmailSucceed(result));
  } catch (error) {
    yield put(
      authResetPasswordByEmailFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

/**
 * 1.3.2 비밀번호 분실 후 변경
 * 1.4 최초 비밀번호 설정
 * 1.5 휴면 계정 복구(이메일 링크로 접근)
 * @param {*} action
 * @returns
 */
function* completeResetPasswordByEmail(action) {
  try {
    const { data } = yield call(
      ApiManager.completeResetPasswordByEmail,
      action.password,
      action.token,
      action.uidb64
    );
    const { result } = data;

    yield put(authCompleteResetPasswordByEmailSucceed(result));
  } catch (error) {
    yield put(
      authCompleteResetPasswordByEmailFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

/**
 * Authorized
 * 1.6 비밀번호 변경 권고
 * @param {*} action
 * @returns
 */
function* resetPasswordNextTime(action) {
  try {
    const { data } = yield call(ApiManager.resetPasswordNextTime);
    const { result } = data;

    yield put(authResetPasswordNextTimeSucceed(result));
  } catch (error) {
    yield put(
      authResetPasswordNextTimeFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

/**
 * Authorized
 * 1.6 비밀번호 변경 권고
 * 7.2 비밀번호 변경
 * @param {*} action
 * @returns
 */
function* resetPasswordWithOld(action) {
  try {
    const { data } = yield call(
      ApiManager.resetPasswordWithOld,
      action.oldPassword,
      action.newPassword
    );
    const { result } = data;

    yield put(authResetPasswordWithOldSucceed(result));
  } catch (error) {
    yield put(
      authResetPasswordWithOldFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

function* login(action) {
  try {
    const { data } = yield call(
      ApiManager.login,
      action.email,
      action.password
    );
    const { result } = data;

    const {
      tokenType,
      accessToken,
      refreshToken,
      email,
      username,
      ...restData
    } = result;
    // restData: expiresIn, tokenType, scope, code
    LocalStorageManager.setItem(LocalStorageKey.TOKEN_TYPE, tokenType);

    LocalStorageManager.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
    LocalStorageManager.setItem(LocalStorageKey.REFESH_TOKEN, refreshToken);
    // yield put(authLoginSucceed(restData, { email, username }));
    // 로그인 성공 하면 사용자 정보 요청
    yield put(authReadMyInformationRequested(restData));
  } catch (error) {
    yield put(
      authLoginFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

function* readMyInformation(action) {
  try {
    const { data } = yield call(ApiManager.readMyInformation);
    const { result } = data;

    yield put(authReadMyInformationSucceed(result, action.data));
  } catch (error) {
    yield put(
      authReadMyInformationFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

/**
 * 로그아웃 API를 통해 서버에 저장된 사용자 Token 정보 삭제처리와 함께 클라이언트의 로그아웃 처리
 * 하려 했으나 클라이언트 로그아웃 처리만 적용!!!
 * TODO: saga를 거치지 않고 reducer에서 삭제 처리 하는것이 적절할 수 있음
 * @param {*} action
 * @returns
 */
function* logout(action) {
  try {
    LocalStorageManager.clear();

    yield put(authLogoutSucceed());
  } catch (error) {
    yield put(
      authLogoutFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

/**
 * Authorized
 *
 * @param {*} action
 * @returns
 */
function* readUserConsent(action) {
  try {
    const { data } = yield call(ApiManager.readUserConsent);
    const { result } = data;

    yield put(authReadUserConsentSucceed(result));
  } catch (error) {
    yield put(
      authReadUserConsentFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

/**
 * Authorized
 *
 * @param {*} action
 * @returns
 */
function* writeUserConsent(action) {
  try {
    const { data } = yield call(
      ApiManager.writeUserConsent,
      action.isConfirmed
    );

    yield put(authWriteUserConsentSucceed(data));
    yield put(authReadUserConsentRequested());
  } catch (error) {
    yield put(
      authWriteUserConsentFailed({
        name: error.name,
        status: error.status,
        message: error.message,
      })
    );
  }
}

export function* saga() {
  yield takeLatest(CHECK_EMAIL_REQUESTED, checkEmail);
  yield takeLatest(RESET_PASSWORD_REQUESTED, resetPassword);
  yield takeLatest(RESET_PASSWORD_BY_EMAIL_REQUESTED, resetPasswordByEmail);
  yield takeLatest(
    COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED,
    completeResetPasswordByEmail
  );
  yield takeLatest(RESET_PASSWORD_NEXT_TIME_REQUESTED, resetPasswordNextTime);
  yield takeLatest(RESET_PASSWORD_WITH_OLD_REQUESTED, resetPasswordWithOld);
  yield takeLatest(LOGIN_REQUESTED, login);
  yield takeLatest(READ_MY_INFORMATION_REQUESTED, readMyInformation);
  yield takeLatest(LOGOUT_REQUESTED, logout);
  yield takeLatest(READ_USER_CONSENT_REQUESTED, readUserConsent);
  yield takeLatest(WRITE_USER_CONSENT_REQUESTED, writeUserConsent);
}
