const DialogUtil = {
  holdBody: () => {
    document.body.style.overflowY = 'hidden';
  },
  unholdBody: () => {
    document.body.style.overflowY = 'auto';
  },
};

export default DialogUtil;
