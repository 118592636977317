import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  debounce,
  fork,
  delay,
} from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';
import {
  EVENT_CONST_TYPES,
  EVENT_GROUP_TYPE,
  EVENT_SECTION,
  SIDE_PANEL_EVENT_GROUP,
} from 'constant/EventConst';
import { getEventInfoByType } from 'util/EventConstUtil';
import {
  TEN_SEC_STRIP_EDIT,
  TEN_SEC_STRIP_DETAIL,
} from 'constant/ChartEditConst';
import {
  getSearchBeatsNEctopicListRangeAfterUpdateEvent,
  transformEctopicWithBeats,
} from 'util/TestResultDuckUtil';
import {
  getBeatsNEctopicListRequested,
  getBeatsNEctopicListSucceed,
  getEcgsStatisticsRequest,
  selectRecordingTime,
} from './testResultDuck';
import { axiosSourceManager } from 'network/RestClient';

// Selector
// Actions
// etc function
// Reducer
// Action Creators
// Saga functions
// Saga

// Selector
const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;
const eventDetailState = (state) => state.beatReviewReducer.eventDetail;
const recordingTime = (state) => state.testResultReducer.recordingTime;
const sidePanelState = (state) => state.beatReviewReducer.sidePanelState;
const selectPollingData = (state) => state.beatReviewReducer.pollingData;
const selectStatistics = (state) => state.testResultReducer.ecgStatistics.data;

// Actions
// Actions - Global State Actions
const RESET_BEAT_REVIEW_STATE =
  'memo-web/test-result/beat/RESET_BEAT_REVIEW_STATE';
const SET_SELECTED_DIV_INDEX =
  'memo-web/test-result/beat/SET_SELECTED_DIV_INDEX';
const SET_BEAT_REVIEW_TENSEC_STRIP_DETAIL =
  'memo-web/test-result/beat/SET_BEAT_REVIEW_TENSEC_STRIP_DETAIL';
const SET_IS_BEAT_UPDATED = 'memo-web/test-result/beat/SET_IS_BEAT_UPDATED';
const SET_BEAT_REVIEW_SIDE_PANEL_SELECTED_VALUE_LIST =
  'memo-web/test-result/beat/SET_BEAT_REVIEW_SIDE_PANEL_SELECTED_VALUE_LIST';
const GET_EVENT_DETAIL_REQUESTED =
  'memo-web/test-result/beat/GET_EVENTS_DETAIL_REQUESTED';
const GET_EVENT_DETAIL_SUCCEED =
  'memo-web/test-result/beat/GET_EVENTS_DETAIL_SUCCEED';
const GET_EVENT_DETAIL_FAILED =
  'memo-web/test-result/beat/GET_EVENTS_DETAIL_FAILED';
// Actions - Post, Patch, Delete Beats
const POST_BEATS_REQUESTED_BEAT_REVIEW =
  'memo-web/test-result/beat/POST_BEATS_REQUESTED_BEAT_REVIEW';
const POST_BEATS_SUCCEED_BEAT_REVIEW =
  'memo-web/test-result/beat/POST_BEATS_SUCCEED_BEAT_REVIEW';
const POST_BEATS_FAILED_BEAT_REVIEW =
  'memo-web/test-result/beat/POST_BEATS_FAILED_BEAT_REVIEW';
const PATCH_BEATS_REQUESTED_BEAT_REVIEW =
  'memo-web/test-result/beat/PATCH_BEATS_REQUESTED_BEAT_REVIEW';
const PATCH_BEATS_SUCCEED_BEAT_REVIEW =
  'memo-web/test-result/beat/PATCH_BEATS_SUCCEED_BEAT_REVIEW';
const PATCH_BEATS_FAILED_BEAT_REVIEW =
  'memo-web/test-result/beat/PATCH_BEATS_FAILED_BEAT_REVIEW';
const DELETE_BEATS_REQUESTED_BEAT_REVIEW =
  'memo-web/test-result/beat/DELETE_BEATS_REQUESTED_BEAT_REVIEW';
const DELETE_BEATS_SUCCEED_BEAT_REVIEW =
  'memo-web/test-result/beat/DELETE_BEATS_SUCCEED_BEAT_REVIEW';
const DELETE_BEATS_FAILED_BEAT_REVIEW =
  'memo-web/test-result/beat/DELETE_BEATS_FAILED_BEAT_REVIEW';
const DELETE_REPORT_EVENT_REQUESTED_BEAT_REVIEW =
  'memo-web/test-result/DELETE_REPORT_EVENT_REQUESTED_BEAT_REVIEW';
const DELETE_REPORT_EVENT_SUCCEED_BEAT_REVIEW =
  'memo-web/test-result/DELETE_REPORT_EVENT_SUCCEED_BEAT_REVIEW';
const DELETE_REPORT_EVENT_FAILED_BEAT_REVIEW =
  'memo-web/test-result/DELETE_REPORT_EVENT_FAILED_BEAT_REVIEW';
/*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
const SET_POLLING_DATA = 'memo-web/beat-review/SET_POLLING_DATA';
const RESET_POLLING_DATA = 'memo-web/beat-review/RESET_POLLING_DATA';
/*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/

const initialState = {
  selectedDivIndex: 1,
  isBeatUpdated: false,
  sidePanelState: {
    selectedValueList: [],
  },
  eventDetail: {
    pending: false,
    data: null,
    error: null,
  },
  tenSecStripDetail: {
    onsetMs: undefined,
    terminationMs: undefined,
    onsetWaveformIdx: undefined,
    terminationWaveformIdx: undefined,
    hrAvg: undefined,
    ecgRaw: [],
    beatLabelButtonDataList: undefined,
    pending: false,
    error: null,
  },
  reportEvents: {
    pending: false,
    error: null,
  },
  /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
  pollingData: [],
  /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_BEAT_REVIEW_STATE:
      return {
        selectedDivIndex: 1,
        isBeatUpdated: false,
        sidePanelState: {
          selectedValueList: [],
        },
        eventDetail: {
          pending: false,
          data: null,
          error: null,
        },
        tenSecStripDetail: {
          onsetMs: undefined,
          terminationMs: undefined,
          onsetWaveformIdx: undefined,
          terminationWaveformIdx: undefined,
          hrAvg: undefined,
          ecgRaw: [],
          beatLabelButtonDataList: undefined,
          pending: false,
          error: null,
        },
        /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
        pollingData: [],
        /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
      };
    case SET_SELECTED_DIV_INDEX:
      return {
        ...state,
        selectedDivIndex: action.divIndex,
      };
    case SET_BEAT_REVIEW_TENSEC_STRIP_DETAIL:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          ...action.data,
        },
      };
    case SET_IS_BEAT_UPDATED:
      return {
        ...state,
        isBeatUpdated: action.isBeatUpdated,
      };
    case SET_BEAT_REVIEW_SIDE_PANEL_SELECTED_VALUE_LIST:
      return {
        ...state,
        sidePanelState: {
          ...state.sidePanelState,
          selectedValueList: action.newSelectedValueList,
        },
      };
    case GET_EVENT_DETAIL_REQUESTED:
      return {
        ...state,
        eventDetail: {
          ...state.eventDetail,
          pending: true,
          error: null,
        },
      };
    case GET_EVENT_DETAIL_SUCCEED:
      return {
        ...state,
        eventDetail: {
          pending: false,
          data: action.data,
        },
        sidePanelState: {
          ...state.sidePanelState,
          selectedValueList: action.newSelectedValueList,
        },
      };
    case GET_EVENT_DETAIL_FAILED:
      return {
        ...state,
        eventDetail: {
          pending: false,
          error: action.error,
        },
      };
    // POST Beats - 비트추가
    case POST_BEATS_REQUESTED_BEAT_REVIEW:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    case POST_BEATS_SUCCEED_BEAT_REVIEW:
      let newBeatLabelButtonDataListAfterPostBeats;
      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        const editTargetWaveformIndex =
          apiResResult.waveformIndex[0] - onsetWaveformIdx;
        const editTargetBeatType = apiResResult.beatType[0];
        const nextIndexOfAddBeat = beatLabelButtonDataList.findIndex(
          (v) => v.xAxisPoint > editTargetWaveformIndex
        );
        beatLabelButtonDataList.splice(nextIndexOfAddBeat, 0, {
          xAxisPoint: editTargetWaveformIndex,
          beatType: editTargetBeatType,
          title: TEN_SEC_STRIP_EDIT.BEAT_TYPE[editTargetBeatType],
          color: TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[editTargetBeatType],
          isSelected: false,
          isEventReview: '',
        });
        newBeatLabelButtonDataListAfterPostBeats = beatLabelButtonDataList;
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: [
            ...newBeatLabelButtonDataListAfterPostBeats,
          ],
          pending: false,
          error: null,
        },
      };
    case POST_BEATS_FAILED_BEAT_REVIEW:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
      };
    // PATCH Beats
    case PATCH_BEATS_REQUESTED_BEAT_REVIEW:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    case PATCH_BEATS_SUCCEED_BEAT_REVIEW:
      // 업데이트 성공시 get 재호출
      // 10s strip detail에서 업데이트 하는 경우만
      let newBeatLabelButtonDataListAfterPatchBeats;
      let updateBeatLabelButtonDataList = false;

      (function () {
        const {
          data: { result: apiResResult },
          tabType,
        } = action;

        if (tabType === TEN_SEC_STRIP_DETAIL.TAB.ARRHYTHMIA_CONTEXTMENU) return;

        updateBeatLabelButtonDataList = true;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        for (let i in apiResResult.waveformIndex) {
          newBeatLabelButtonDataListAfterPatchBeats =
            beatLabelButtonDataList.map((v) => {
              if (
                v.xAxisPoint ===
                apiResResult.waveformIndex[i] - onsetWaveformIdx
              ) {
                v.isSelected = false;
                v.beatType = apiResResult.beatType[i];
                v.title = TEN_SEC_STRIP_EDIT.BEAT_TYPE[v.beatType];
                v.color = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE[v.beatType];
                return v;
              }
              return v;
            });
        }
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: updateBeatLabelButtonDataList
            ? [...newBeatLabelButtonDataListAfterPatchBeats]
            : state.tenSecStripDetail.beatLabelButtonDataList,
          pending: false,
          error: null,
        },
      };
    case PATCH_BEATS_FAILED_BEAT_REVIEW:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
      };
    // DELETE Beats
    case DELETE_BEATS_REQUESTED_BEAT_REVIEW:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: true,
          error: null,
        },
      };
    case DELETE_BEATS_SUCCEED_BEAT_REVIEW:
      let newBeatLabelButtonDataListAfterDeleteBeats;

      (function () {
        const { reqBody } = action;
        const { beatLabelButtonDataList, onsetWaveformIdx } =
          state.tenSecStripDetail;

        const editTargetWaveformIndex =
          reqBody.waveformIndexes[0] - onsetWaveformIdx;
        const nextIndexOfAddBeat = beatLabelButtonDataList.findIndex(
          (v) => v.xAxisPoint > editTargetWaveformIndex
        );
        beatLabelButtonDataList.splice(nextIndexOfAddBeat - 1, 1);
        newBeatLabelButtonDataListAfterDeleteBeats = beatLabelButtonDataList;
      })();

      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          beatLabelButtonDataList: [
            ...newBeatLabelButtonDataListAfterDeleteBeats,
          ],
          pending: false,
          error: null,
        },
      };
    case DELETE_BEATS_FAILED_BEAT_REVIEW:
      return {
        ...state,
        tenSecStripDetail: {
          ...state.tenSecStripDetail,
          pending: false,
          error: null,
        },
      };
    case DELETE_REPORT_EVENT_REQUESTED_BEAT_REVIEW:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: true,
        },
      };
    case DELETE_REPORT_EVENT_SUCCEED_BEAT_REVIEW:
      return {
        ...state,
        reportEvents: {
          ...state.reportEvents,
          pending: false,
        },
      };
    case DELETE_REPORT_EVENT_FAILED_BEAT_REVIEW:
      return {
        ...state,
        reportEvents: {
          pending: false,
          error: action.error,
        },
      };
    /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
    case SET_POLLING_DATA:
      const position = action.pollingData[0].position;

      let copyArray = [...state.pollingData];
      copyArray.push(
        ...action.pollingData.filter(
          (el) => !copyArray.find((v) => el.position === v.position)
        )
      );
      let resultArray = copyArray;
      if (position % 100 <= 5) {
        resultArray = copyArray.filter((el) => el.position >= position - 80);
      }
      console.log(resultArray.length, 'stored array length');
      return {
        ...state,
        pollingData: resultArray,
      };
    case RESET_POLLING_DATA:
      return {
        ...state,
        pollingData: [],
      };
    /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
    default:
      return state;
  }
}

// Action Creators
// Action Creators - Setter
export function resetBeatReviewState() {
  return { type: RESET_BEAT_REVIEW_STATE };
}
export function setSelectedDivIndex(divIndex) {
  return { type: SET_SELECTED_DIV_INDEX, divIndex };
}
function setBeatTenSecStripDetail(data) {
  return { type: SET_BEAT_REVIEW_TENSEC_STRIP_DETAIL, data };
}
export function setIsBeatUpdated(isBeatUpdated) {
  return { type: SET_IS_BEAT_UPDATED, isBeatUpdated };
}
export function setBeatReviewSidePanelSelectedValueList(
  newSelectedValueList,
  isFromChart = false
) {
  return {
    type: SET_BEAT_REVIEW_SIDE_PANEL_SELECTED_VALUE_LIST,
    newSelectedValueList,
    isFromChart,
  };
}
// Action Creators - Get single events
export function getEventDetailRequested(
  eventType,
  eventId,
  position,
  isSelectedValueUpdate,
  updatedForPolling
) {
  return {
    type: GET_EVENT_DETAIL_REQUESTED,
    eventType,
    eventId,
    position,
    isSelectedValueUpdate,
    updatedForPolling,
  };
}
function getEventDetailSucceed(data, newSelectedValueList) {
  return {
    type: GET_EVENT_DETAIL_SUCCEED,
    data,
    newSelectedValueList,
  };
}
function getEventDetailFailed(error) {
  return { type: GET_EVENT_DETAIL_FAILED, error };
}

// post beats
export function postBeatsRequestedBeatReview(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: POST_BEATS_REQUESTED_BEAT_REVIEW,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
export function postBeatsSucceedBeatReview(data) {
  return {
    type: POST_BEATS_SUCCEED_BEAT_REVIEW,
    data,
  };
}
export function postBeatsFailedBeatReview(error) {
  return {
    type: POST_BEATS_FAILED_BEAT_REVIEW,
    error,
  };
}

// patch beats
export function patchBeatsRequestedBeatReview(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: PATCH_BEATS_REQUESTED_BEAT_REVIEW,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
export function patchBeatsSucceedBeatReview(data, tabType) {
  return {
    type: PATCH_BEATS_SUCCEED_BEAT_REVIEW,
    data,
    tabType,
  };
}
export function patchBeatsFailedBeatReview(error) {
  return {
    type: PATCH_BEATS_FAILED_BEAT_REVIEW,
    error,
  };
}

// delete beats
export function deleteBeatsRequestedBeatReview(
  reqBody,
  onsetWaveformIndex,
  terminationWaveformIndex,
  suffix,
  tabType
) {
  return {
    type: DELETE_BEATS_REQUESTED_BEAT_REVIEW,
    reqBody,
    onsetWaveformIndex,
    terminationWaveformIndex,
    suffix,
    tabType,
  };
}
export function deleteBeatsSucceedBeatReview(reqBody) {
  return {
    type: DELETE_BEATS_SUCCEED_BEAT_REVIEW,
    reqBody,
  };
}
export function deleteBeatsFailedBeatReview(error) {
  return {
    type: DELETE_BEATS_FAILED_BEAT_REVIEW,
    error,
  };
}

// delete Report
export function deleteReportEventRequestedBeatReview(reportEventId) {
  return { type: DELETE_REPORT_EVENT_REQUESTED_BEAT_REVIEW, reportEventId };
}
function deleteReportEventSucceedBeatReview() {
  return { type: DELETE_REPORT_EVENT_SUCCEED_BEAT_REVIEW };
}
function deleteReportEventFailedBeatReview(error) {
  return { type: DELETE_REPORT_EVENT_FAILED_BEAT_REVIEW, error };
}

/*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
function setPollingData(pollingData) {
  return { type: SET_POLLING_DATA, pollingData };
}
export function resetPollingData() {
  return { type: RESET_POLLING_DATA };
}
/*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/

// Saga functions
function* _setSelectedValueListHandler(action) {
  try {
    const { newSelectedValueList } = action;

    const newSelectedValue = newSelectedValueList[0];
    const eventId =
      newSelectedValue.timeEventId || newSelectedValue.waveformIndex;
    yield put(
      getEventDetailRequested(
        newSelectedValue.type,
        eventId,
        newSelectedValue.position,
        newSelectedValue.type !== EVENT_CONST_TYPES.PATIENT,
        false
      )
    );
  } catch (error) {
    console.error(error);
  }
}

let cancelTokenFlag;
function* fetchAllDataPolling(param) {
  try {
    if (cancelTokenFlag !== param.ectopicType + param.beatType) {
      param.source = axiosSourceManager.getSource();
    }
    cancelTokenFlag = param.ectopicType + param.beatType;
    // const _selectStatistics = yield select(selectStatistics);
    // const _sidePanelState = yield select(sidePanelState);

    // const maxPosition =
    //   _selectStatistics[
    //     SIDE_PANEL_EVENT_GROUP[EVENT_GROUP_TYPE.BEATS].filter(
    //       (event) => _sidePanelState.selectedValueList[0].type === event.type
    //     )[0].eventSection
    //   ];

    // console.log(_selectStatistics, _sidePanelState.selectedValueList[0].type);
    const position = param.position;
    let pollingPosition = position;
    while (pollingPosition <= position + 50) {
      yield fork(fetchDataPolling, {
        ...param,
        position: pollingPosition,
      });
      pollingPosition += 25;
      // if (maxPosition === pollingPosition - 1) return;
      // if (pollingPosition % 5 === 0) yield delay(300);
    }
  } catch (error) {
    console.log('error: ', error);
  }
}

function* fetchDataPolling(param) {
  try {
    // const _selectPollingData = yield select(selectPollingData);
    // if (_selectPollingData.filter((el) => el.param.position).length === 0) {
    const result = yield call(ApiManager.getEctopicFilterBulkType, param);
    // console.log('result: ', result);
    const results = result?.data?.results;
    if (results) {
      yield put(setPollingData(results));
    }
    // }
  } catch (error) {
    console.error({ error });
  }
}
/*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/

function* _getEventDetail(action) {
  try {
    const source = axiosSourceManager.getSource();
    source.cancel(' ### cancel ### ');
    axiosSourceManager.initAxiosSource();

    const ecgTestId = yield select(selectEcgTestId);
    /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/

    const _selectPollingData = yield select(selectPollingData);
    /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
    const {
      eventType,
      eventId,
      position,
      isSelectedValueUpdate,
      updatedForPolling,
    } = action;
    let newSelectedValue = null;
    if (updatedForPolling) yield put(resetPollingData());

    const eventInfo = getEventInfoByType(eventType);
    let responseData;
    if (eventInfo?.ectopicType) {
      // Ectopic 데이터 조회
      let ectopicInfo;
      const { beatType, ectopicType } = eventInfo;
      const isIncludeAll = true;
      /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
      if (position % 30 === 0 || updatedForPolling) {
        const {
          data: { results },
        } = yield call(ApiManager.getEctopicListFilterType, {
          ecgTestId,
          beatType,
          ectopicType,
          position,
          isIncludeAll,
        });
        yield put(setPollingData(results));

        yield fork(fetchAllDataPolling, {
          ecgTestId,
          beatType,
          ectopicType,
          position:
            updatedForPolling ||
            _selectPollingData.filter(
              (el) => el.position === position && el.position === position + 1
            ).length === 0
              ? position + 1
              : position + 30,
          isIncludeAll,
          perPage: 25,
        });
        ectopicInfo = results[0];
      } else if (
        /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
        _selectPollingData.filter(
          (el) => el.position === position && el.position === position + 1
        ).length === 0
      ) {
        if (position === 1) {
          const {
            data: { results },
          } = yield call(ApiManager.getEctopicListFilterType, {
            ecgTestId,
            beatType,
            ectopicType,
            position,
            isIncludeAll,
          });

          ectopicInfo = results[0];
          // 마지막 index를 조회했는데 results가 비어 있을 경우
          if (results.length === 0) {
            if (position - 1 !== 0) {
              const {
                data: { results },
              } = yield call(ApiManager.getEctopicListFilterType, {
                ecgTestId,
                beatType,
                ectopicType,
                position: position - 1,
                isIncludeAll,
              });
              ectopicInfo = results[0];
            } else {
              yield put(resetBeatReviewState());
              return;
            }
          }
          yield fork(fetchAllDataPolling, {
            ecgTestId,
            beatType,
            ectopicType,
            position: position + 1,
            isIncludeAll,
            perPage: 25,
          });
        } else {
          // yield fork(fetchAllDataPolling, {
          //   ecgTestId,
          //   beatType,
          //   ectopicType,
          //   position: position + 20,
          //   isIncludeAll,
          // });
          ectopicInfo = _selectPollingData.filter(
            (el) => el.position === position
          )[0];
          if (
            _selectPollingData.filter((el) => el.position === position + 1)
              .length === 0 ||
            ectopicInfo == null
          ) {
            const {
              data: { results },
            } = yield call(ApiManager.getEctopicFilterBulkType, {
              ecgTestId,
              beatType,
              ectopicType,
              position,
              isIncludeAll,
              perPage: 25,
            });
            yield put(setPollingData(results));
            ectopicInfo = results[0];
          }
        }
      } else {
        /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
        // console.log(
        //   _selectPollingData.filter((el) => el.position === position)[0]
        // );
        ectopicInfo = _selectPollingData.filter(
          (el) => el.position === position
        )[0];
        /*⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️*/
      }
      responseData = {
        ...ectopicInfo,
        onsetWaveformIndex: ectopicInfo.waveformIndex.at(0),
        terminationWaveformIndex: ectopicInfo.waveformIndex.at(-1),
      };
      if (isSelectedValueUpdate) {
        newSelectedValue = {
          position: responseData.position,
          waveformIndex: responseData.waveformIndex[0],
        };
      }
    }

    // Events 텝에서 조회 시 selectedValue 가 수정될 필요 있음(position, timeEventId, waveformIndex)
    const prevSelectedValueList = yield select(
      ({ beatReviewReducer: state }) => state.sidePanelState.selectedValueList
    );
    const newSelectedValueList =
      prevSelectedValueList.length === 1
        ? [
            isSelectedValueUpdate
              ? {
                  type: prevSelectedValueList[0].type,
                  position:
                    newSelectedValue.position ??
                    prevSelectedValueList[0].position,
                  timeEventId:
                    newSelectedValue.timeEventId ??
                    prevSelectedValueList[0].timeEventId,
                  waveformIndex:
                    newSelectedValue.waveformIndex ??
                    prevSelectedValueList[0].waveformIndex,
                }
              : prevSelectedValueList[0],
          ]
        : prevSelectedValueList;

    const leadOffList = yield select(
      ({ testResultReducer: state }) => state.timeEventsList.leadOff
    );
    const { recordingStartMs, recordingEndMs } = yield select(
      selectRecordingTime
    );

    const rawData = [responseData.raw[0]]
      .concat(responseData.raw[1])
      .filter(Boolean);

    const rawEcgData = () => {
      if (!responseData) return;
      const { onsetWaveformIndex } = responseData;

      // Beat Review 검사 시작지점 검증
      if (onsetWaveformIndex < 3750) {
        return [
          ...Array.from({ length: 3750 - onsetWaveformIndex }, () => 0),
        ].concat(rawData[0].ecgData);
      }
      // Beat Review 검사 마지막지점 검증
      const verificationVar =
        (recordingEndMs - recordingStartMs) / 4 - onsetWaveformIndex;
      if (verificationVar < 3750)
        return rawData[0].ecgData
          .concat(
            ...Array.from(
              {
                length: 3750 - verificationVar,
              },
              () => 0
            )
          )
          .slice(3750 - verificationVar);

      if (rawData.length === 1) {
        return rawData[0].ecgData;
      } else {
        const entireEcgDataArray = rawData[0].ecgData.concat(
          rawData[1].ecgData
        );
        return entireEcgDataArray.slice(
          onsetWaveformIndex - rawData[0].onsetWaveformIndex - 3750,
          onsetWaveformIndex - rawData[0].onsetWaveformIndex + 3750
        );
      }
    };

    const result = {
      ...responseData,
      raw: [
        {
          ...responseData.raw[0],
          ecgData: rawEcgData(),
        },
      ],
    };

    yield put(
      getBeatsNEctopicListSucceed(
        transformEctopicWithBeats(
          responseData.beat,
          leadOffList,
          recordingStartMs
        )
      )
    );
    yield put(getEventDetailSucceed(result, newSelectedValueList));
    yield put(setSelectedDivIndex(1));
  } catch (error) {
    yield put(getEventDetailFailed(error));
  }
}

function* _setSelectedDivIndex(action) {
  try {
    const eventDetail = yield select(eventDetailState);
    const { recordingStartMs } = yield select(recordingTime);
    const selectedDiv = action.divIndex;

    const tenSecStripDataHandler = () => {
      if (!eventDetail.data) return;

      const { data } = eventDetail;
      const { ecgData } = data.raw[0];
      const onsetWaveformIndex = data.onsetWaveformIndex - 3750;
      const terminationWaveformIndex = onsetWaveformIndex + 7500;
      const { hr, waveformIndex } = data.beat;

      const _onsetWaveformIndex = onsetWaveformIndex + 2500 * selectedDiv;
      const _terminationWaveformIndex =
        terminationWaveformIndex - 2500 * (2 - selectedDiv);
      const _onsetMs = recordingStartMs + _onsetWaveformIndex * 4;
      const _terminationMs = _onsetMs + 10 * 1000;
      const _ecgRaw = ecgData.slice(
        2500 * selectedDiv,
        2500 * (selectedDiv + 1)
      );

      const hrArray = hr
        .slice(
          waveformIndex.findIndex((v) => v >= _onsetWaveformIndex),
          waveformIndex.findLastIndex((v) => v <= _terminationWaveformIndex) + 1
        )
        .filter(Boolean);
      const sumHr = hrArray.reduce((acc, cur) => acc + cur, 0);
      const tenSecStripHrAvg = Math.floor((sumHr / hrArray.length) * 10) / 10;
      const beatLabelButtonDataList = [];
      const beatType = TEN_SEC_STRIP_EDIT.BEAT_TYPE;
      const beatColorType = TEN_SEC_STRIP_EDIT.BEAT_COLOR_TYPE;
      const waveformIndexList = waveformIndex
        .slice(
          waveformIndex.findIndex((v) => v >= _onsetWaveformIndex),
          waveformIndex.findLastIndex((v) => v <= _terminationWaveformIndex) + 1
        )
        .map((waveformIdx) => waveformIdx - _onsetWaveformIndex);
      const beatTypeList = data.beat.beatType.slice(
        waveformIndex.findIndex((v) => v >= _onsetWaveformIndex),
        waveformIndex.findLastIndex((v) => v <= _terminationWaveformIndex) + 1
      );

      for (let index in waveformIndexList) {
        beatLabelButtonDataList.push({
          xAxisPoint: waveformIndexList[index],
          isSelected:
            waveformIndexList[index] ===
            data.waveformIndex[0] - _onsetWaveformIndex,
          beatType: beatTypeList[index],
          title: beatType[beatTypeList[index]],
          color: beatColorType[beatTypeList[index]],
          isEventReview: '',
        });
      }

      return {
        onsetMs: _onsetMs,
        terminationMs: _terminationMs,
        onsetWaveformIdx: _onsetWaveformIndex,
        terminationWaveformIdx: _terminationWaveformIndex,
        hrAvg: tenSecStripHrAvg,
        ecgRaw: _ecgRaw,
        beatLabelButtonDataList,
      };
    };
    yield put(setBeatTenSecStripDetail(tenSecStripDataHandler()));
  } catch (error) {
    console.error(error);
  }
}

function* _setIsBeatUpdated(action) {
  try {
    const { isBeatUpdated } = action;
    if (isBeatUpdated) return;
    const _sidePanelState = yield select(sidePanelState);
    if (_sidePanelState.selectedValueList[0]?.length === 0) return;

    const { type, timeEventId, position, waveformIndex } =
      _sidePanelState.selectedValueList[0] || {};

    yield put(getEcgsStatisticsRequest());
    yield put(
      getEventDetailRequested(type, timeEventId, position, waveformIndex, true)
    );
  } catch (error) {
    console.error(error);
  }
}

function* _postBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { onsetWaveformIndex, terminationWaveformIndex } = action;
    const { searchOnsetRequest, searchTerminationRequest } =
      getSearchBeatsNEctopicListRangeAfterUpdateEvent(
        onsetWaveformIndex,
        terminationWaveformIndex
      );

    const { data } = yield call(
      ApiManager.postBeats,
      ecgTestId,
      action.suffix,
      action.reqBody
    );

    yield put(postBeatsSucceedBeatReview(data));
    yield put(
      getBeatsNEctopicListRequested(
        searchOnsetRequest,
        searchTerminationRequest
      )
    );
    yield put(setIsBeatUpdated(true));
  } catch (error) {
    yield put(postBeatsFailedBeatReview(error));
  }
}

function* _patchBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      reqBody,
      onsetWaveformIndex,
      terminationWaveformIndex,
      suffix,
      tabType,
    } = action;
    const { searchOnsetRequest, searchTerminationRequest } =
      getSearchBeatsNEctopicListRangeAfterUpdateEvent(
        onsetWaveformIndex,
        terminationWaveformIndex
      );

    const { data } = yield call(
      ApiManager.patchBeats,
      ecgTestId,
      suffix,
      reqBody
    );

    yield put(patchBeatsSucceedBeatReview(data, tabType));
    yield put(
      getBeatsNEctopicListRequested(
        searchOnsetRequest,
        searchTerminationRequest
      )
    );
    yield put(setIsBeatUpdated(true));
  } catch (error) {
    yield put(patchBeatsFailedBeatReview(error));
    console.error(error);
  }
}

function* _deleteBeats(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const { reqBody, onsetWaveformIndex, terminationWaveformIndex, suffix } =
      action;
    const { searchOnsetRequest, searchTerminationRequest } =
      getSearchBeatsNEctopicListRangeAfterUpdateEvent(
        onsetWaveformIndex,
        terminationWaveformIndex
      );

    const { status } = yield call(
      ApiManager.deleteBeats,
      ecgTestId,
      suffix,
      reqBody
    );

    if (status === 204) {
      yield put(deleteBeatsSucceedBeatReview(reqBody));
    }
    yield put(
      getBeatsNEctopicListRequested(
        searchOnsetRequest,
        searchTerminationRequest
      )
    );
    yield put(setIsBeatUpdated(true));
  } catch (error) {
    yield put(deleteBeatsFailedBeatReview(error));
  }
}

function* _deleteReportEvent(action) {
  try {
    const { reportEventId } = action;

    yield call(ApiManager.deleteReportEvents, { reportEventId });
    yield put(deleteReportEventSucceedBeatReview());
    yield put(setIsBeatUpdated(false));
  } catch (error) {
    yield put(deleteReportEventFailedBeatReview(error));
  }
}

// Saga
export function* saga() {
  yield takeLatest(
    SET_BEAT_REVIEW_SIDE_PANEL_SELECTED_VALUE_LIST,
    _setSelectedValueListHandler
  );
  yield takeLatest(SET_SELECTED_DIV_INDEX, _setSelectedDivIndex);
  yield takeLatest(SET_IS_BEAT_UPDATED, _setIsBeatUpdated);
  yield takeEvery(GET_EVENT_DETAIL_REQUESTED, _getEventDetail);
  // :: 10s strip detail - beat edit ::
  yield takeEvery(POST_BEATS_REQUESTED_BEAT_REVIEW, _postBeats); // create beat
  yield takeEvery(PATCH_BEATS_REQUESTED_BEAT_REVIEW, _patchBeats); // modify beat
  yield takeEvery(DELETE_BEATS_REQUESTED_BEAT_REVIEW, _deleteBeats); // delete beat

  // :: Delete Report ::
  yield takeLatest(
    DELETE_REPORT_EVENT_REQUESTED_BEAT_REVIEW,
    _deleteReportEvent
  );
}
