import React from 'react';
import ReactDOM from 'react-dom';
// Internationalization
import { IntlProvider } from 'react-intl';
import Korean from 'lang/compiled/ko.json';
import English from 'lang/compiled/en.json';
// Redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// App
import AppContainer from 'redux/container/AppContainer';
import { disableReactDevTools } from 'util/disableReactDevTools';

import configureStore from 'redux/configureStore';

import * as Sentry from '@sentry/react';

const { store, persistor } = configureStore();

// TODO: Get locale from browser dynamically
const locale = 'ko';

function loadLocaleData(locale) {
  switch (locale) {
    case 'en':
      return English;
    default:
      return Korean;
  }
}

if (process.env.REACT_APP_VERCEL_ENV === 'production') {
  disableReactDevTools();
}

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider
      locale={locale}
      defaultLocale="ko"
      messages={loadLocaleData(locale)}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Sentry.ErrorBoundary>
            <AppContainer />
          </Sentry.ErrorBoundary>
        </PersistGate>
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
