import React from 'react';
import styled from 'styled-components';
import {
  showDissolveAnim,
  hideDissolveAnim,
} from 'component/ui/animation/Animation';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props) => (props.zIndex ?? 10) + 8000};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  animation: ${(props) => (props.visible ? showDissolveAnim : hideDissolveAnim)}
    0.2s ease-in-out;
  transform-origin: top;
  -webkit-transition: -webkit-visibility 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out;
`;

export const InnerContainer = styled.div`
  ${(props) =>
    props.maxWidth &&
    `
        max-width: ${props.maxWidth}px;
    `}
  ${(props) =>
    props.targetWidth &&
    `
            width: ${Math.min(
              window.screen.width - 64,
              props.targetWidth - 64
            )}px;
        `};
  min-width: ${Math.min(window.screen.width - 64, 360 - 64)}px;
  margin: 0px 16px;
  padding: 20px;
  background-color: ${(props) => props.theme.color.WHITE};
  border-radius: 6px;
  box-shadow: 0 0 32px 0 rgba(48, 48, 48, 0.1);
`;

export function DialogWrapper(props) {
  const {
    //
    open,
    targetWidth,
    maxWidth,
    zIndex,
    innerStyle,
    children,
  } = props;
  if (!open) {
    return null;
  }

  return (
    <Wrapper visible={open} zIndex={zIndex}>
      <InnerContainer
        targetWidth={targetWidth}
        maxWidth={maxWidth}
        style={innerStyle}>
        {children}
      </InnerContainer>
    </Wrapper>
  );
}

export const DialogTitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
  color: ${(props) => props.theme.color.BLACK};
`;

export const DialogMessageText = styled.div`
  white-space: pre-wrap;
  margin-bottom: 16px;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
`;

export const DialogContentContainer = styled.div`
  // padding: 16px 0px;
  margin: 12px 0px;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

// FIXME(준호): 컴포넌트 최종안에서 텍스트 버튼 디자인 케이스 반영 필요!!!
export const DialogButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: auto -20px -20px;
`;
