import { connect } from 'react-redux';
import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import { authLogoutRequested } from 'redux/duck/authDuck';
import AlertExpireDialog from 'component/dialog/AlertExpireDialog';

const mapStateToProps = (state, ownProps) => {
  return {
    // authState: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // Auth
    logout: () => {
      dispatch(authLogoutRequested());
    },
  };
};

const AlertExpireDialogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertExpireDialog);

export default AlertExpireDialogContainer;
