import React, { forwardRef } from 'react';
import styled from 'styled-components';
import ButtonSpinner from './ButtonSpinner';

const Wrapper = styled.button`
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  // letter-spacing: -0.34px;
  text-align: center;
  background-color: transparent;
  ${(props) => {
    let result;
    if (!props.disabled) {
      result = `:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
      }`;
    }
    return result;
  }}

  color: ${(props) => props.textColor || props.theme.color.PRIMARY_BLUE};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  transition: background-color 0.2s ease-in-out;
  border: none;
  &:focus {
    border: none;
    outline: none;
  }
`;

function TextButton(props, ref) {
  const { style, disabled, pending, title, textColor, onClick } = props;

  return (
    <Wrapper
      ref={ref}
      style={style}
      disabled={disabled}
      onClick={(event) => {
        if (!(disabled || pending)) {
          onClick(event);
        }
      }}
      textColor={textColor}>
      {pending ? <ButtonSpinner reverse={true} /> : title}
    </Wrapper>
  );
}

export default forwardRef(TextButton);
