const serialNumberRegExp = new RegExp(/HPTA[0-9A-Z]{9}$/g);
const timestampRegExp = new RegExp(/[0-9]{10}$/g);

function byteArrayToTimestamp(byteArray) {
  let value = 0;
  for (let i = 0; i < byteArray.length; i++) {
    value = value * 256 + byteArray[i];
  }
  return value;
}

const FileUtil = {
  extractMetadata: async (file) => {
    const buffer = await file.arrayBuffer();
    /**
     * 22" 10. 25. Binary 파일의 시리얼 넘버 포멧 변경에 따른 수정 진행
     *
     * 시리얼 넘버는 12자리 문자열(숫자와 대문자 영문자로 구성)
     *
     * 13 번째 자리 문자는 유니코드 Null 문자로 구분되어 있음
     */
    // const serialSlice = buffer.slice(0, 13);
    const serialSlice = buffer.slice(0, 12);
    const startTimestampSlice = buffer.slice(13, 18);

    let serialSliceUintArr = new Uint8Array(serialSlice);
    // XXX: 준호 - 13자리 우선 추출 후 마지막 자리 문자열이 Null 이면 12자리로 변경하는 로직??
    // if (serialSliceUintArr[12] === 0xff) {
    //   serialSliceUintArr = serialSliceUintArr.slice(0, 12);
    // }

    const serialNumber = String.fromCharCode.apply(null, serialSliceUintArr);
    const startTimestamp = byteArrayToTimestamp(
      new Uint8Array(startTimestampSlice)
    );

    if (!Number.isInteger(startTimestamp)) {
      throw new Error('Invalid ECG file.');
    }

    return [serialNumber, startTimestamp];
  },

  readFileAsync: (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(file);
    });
  },
};

export default FileUtil;
