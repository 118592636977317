import React, { useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import loadable from '@loadable/component';
// Emotion
import {
  createTheme,
  ThemeProvider as EmotionThemeProvider,
} from '@mui/material/styles';
// Styled-components
import { ThemeProvider } from 'styled-components';
import StyledComponentsTheme from 'theme/StyledComponentsTheme';
// Dialog
import CentralizedDialogContainer from 'redux/container/dialog/CentralizedDialogContainer';
// Layouts
import FullScreenLayout from 'component/base/FullScreenLayout';
import Layout from 'component/base/Layout';
// Etc
import Const from 'constant/Const';
import { debounce } from 'util/Utility';
import TrickUtil from 'util/TrickUtil';
import DialogUtil from 'util/DialogUtil';
// Logging
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

// Pages
const LoginPageContainer = loadable(() =>
  import('redux/container/LoginPageContainer')
);
const ChangePasswordPageContainer = loadable(() =>
  import('redux/container/ChangePasswordPageContainer')
);
const ReleaseDormantPageContainer = loadable(() =>
  import('redux/container/ReleaseDormantPageContainer')
);
const MainPageContainer = loadable(() =>
  import('redux/container/MainPageContainer')
);
const TestResultPageContainer = loadable(() =>
  import('redux/container/TestResultPageContainer')
);
const MyAccountPageContainer = loadable(() =>
  import('redux/container/MyAccountPageContainer')
);
const UserManagementPageContainer = loadable(() =>
  import('redux/container/UserManagementPageContainer')
);
const UsageAgreementPageContainer = loadable(() =>
  import('redux/container/UsageAgreementPageContainer')
);
const PrivacyPolicyPage = loadable(() =>
  import('component/page/PrivacyPolicyPage')
);
const NotFoundPage = loadable(() => import('component/page/NotFoundPage'));

if (
  process.env.REACT_APP_SENTRY_DNS &&
  process.env.REACT_APP_SENTRY_SAMPLE_RATE
)
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: `${process.env.REACT_APP_VERCEL_ENV ?? ''}_${
      process.env.REACT_APP_CUSTOM_ENV ?? ''
    }`,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
  });

function App(props) {
  const {
    // Redux state
    someDialogOpenedState,
    authState,
    // Redux dispatch
    showDialog,
    logout,
    readMeInfo,
  } = props;

  const favicon = useMemo(
    () => document.querySelectorAll('link[rel=icon]')[0],
    []
  );
  useEffect(() => {
    if (process.env.REACT_APP_CUSTOM_ENV === 'prod') {
      favicon.href = '/favicon_production/favicon.ico';
    } else if (process.env.REACT_APP_CUSTOM_ENV === 'qa') {
      favicon.href = '/favicon_staging/favicon.ico';
    } else if (process.env.REACT_APP_CUSTOM_ENV === 'dev') {
      favicon.href = '/favicon_dev/favicon.ico';
    } else {
      favicon.href = '/favicon_aux/favicon.ico';
    }
  }, []);

  let timeoutId = null;
  let flag = false;

  useEffect(() => {
    TrickUtil.setExpireAlert(() => showDialog('AlertExpireDialog'));
    TrickUtil.setNetworkAlert((errorInfo) =>
      showDialog('AlertNetworkDialog', { errorInfo })
    );
  }, []);

  useEffect(() => {
    someDialogOpenedState ? DialogUtil.holdBody() : DialogUtil.unholdBody();
  }, [someDialogOpenedState]);

  useEffect(() => {
    if (authState.isLoggedIn && !authState?.me?.firstName) {
      readMeInfo();
    }
  }, [authState.isLoggedIn]);

  const handleEvent = (e) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (
      authState.isLoggedIn &&
      !flag &&
      !window.location.pathname.includes('login')
    ) {
      const duration = Const.SESSION_DURATION_TIME * 60 * 60 * 1000;

      timeoutId = setTimeout(() => {
        flag = true;
        showDialog(
          'AlertDialog',
          {
            message: `${Const.SESSION_DURATION_TIME}시간 동안 사용하지 않아 자동 로그아웃 되었습니다.`,
          },
          () => {
            flag = false;
            logout();
          }
        );
      }, duration);
    }
  };

  useEffect(() => {
    // TODO(???): 로그인 직후 아무동작도 안할 시 해당 이벤트 핸들러 실행되지 않음

    const debouncedHandlerEvent = debounce(handleEvent, 500);
    const handleEventList = ['scroll', 'click', 'mousemove', 'keydown'];

    for (const e of handleEventList) {
      document.addEventListener(e, debouncedHandlerEvent);
    }

    return () => {
      for (const e of handleEventList) {
        document.removeEventListener(e, debouncedHandlerEvent);
      }
    };
  }, [authState.isLoggedIn]);

  const theme = createTheme(StyledComponentsTheme);

  return (
    <EmotionThemeProvider theme={theme}>
      <ThemeProvider theme={StyledComponentsTheme}>
        <Router>
          <Switch>
            {/* Signing pages (FullScreenLayout) */}
            <SentryRoute path={['/login']}>
              {authState.isLoggedIn ? (
                <Redirect to="/" />
              ) : (
                <FullScreenLayout>
                  <SentryRoute path="/login" component={LoginPageContainer} />
                </FullScreenLayout>
              )}
            </SentryRoute>

            {/* Password Reset Cases Without Login (Layout) */}
            <SentryRoute path="/reset-password">
              <Layout>
                <Switch>
                  <SentryRoute
                    path={['/reset-password/forgot', '/reset-password/new']}
                    component={ChangePasswordPageContainer}
                  />
                  <SentryRoute
                    path={['/reset-password/release-dormant']}
                    component={ReleaseDormantPageContainer}
                  />
                  <Redirect to="/not-found" />
                </Switch>
              </Layout>
            </SentryRoute>

            {/* Other pages (Layout) */}
            <Route path="*">
              {!authState.isLoggedIn ? (
                <Redirect to="/login" />
              ) : authState.data.code === 1001 ? (
                <Layout>
                  <Switch>
                    <SentryRoute
                      path="/change-password"
                      component={ChangePasswordPageContainer}
                    />
                    <Redirect to="/change-password/recommend" />
                  </Switch>
                </Layout>
              ) : authState.data.code === 1002 ? (
                <Layout>
                  <Switch>
                    <SentryRoute
                      path="/release-dormant"
                      component={ReleaseDormantPageContainer}
                    />
                    <Redirect to="/release-dormant" />
                  </Switch>
                </Layout>
              ) : (
                // ) : !authState.me.isConfirmed ? (
                //     <Layout>
                //         <Switch>
                //             <Route
                //                 path="/usage-agreement"
                //                 component={UsageAgreementPageContainer}
                //             />
                //             <Route
                //                 path="/privacy"
                //                 component={PrivacyPolicyPage}
                //             />
                //             <Redirect to="/usage-agreement" />
                //         </Switch>
                //     </Layout>
                <Layout>
                  <Switch>
                    <SentryRoute exact path="/" component={MainPageContainer} />
                    <SentryRoute
                      exact
                      path="/test/:ecgTestId"
                      component={TestResultPageContainer}
                    />
                    {/* <Route
                      exact
                      path="/patient/:patientId"
                      component={PrescriptionResultPageContainer}
                    /> */}
                    {/* <Route
                    exact
                    path="/patient/:patientId/entire-ecg"
                    component={EntireECGFragmentContainer}
                  /> */}
                    <SentryRoute
                      exact
                      path="/my-account"
                      component={MyAccountPageContainer}
                    />
                    {/* <Route path="/usage-agreement">
                                        <Redirect to="/" />
                                    </Route> */}
                    <SentryRoute
                      path="/change-password"
                      component={ChangePasswordPageContainer}
                    />
                    <SentryRoute
                      path="/privacy"
                      component={PrivacyPolicyPage}
                    />
                    {/* <Route
                                        path="/user-management"
                                        component={UserManagementPageContainer}
                                    /> */}

                    <SentryRoute
                      exact
                      path="/not-found"
                      component={NotFoundPage}
                    />
                    <Redirect to="/not-found" />
                  </Switch>
                </Layout>
              )}
            </Route>
          </Switch>
        </Router>

        {/* Centralized Dialogs */}
        <CentralizedDialogContainer {...props} />
      </ThemeProvider>
    </EmotionThemeProvider>
  );
}

export default Sentry.withProfiler(App);
