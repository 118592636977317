import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Avatar from 'component/ui/image/Avatar';
import Menu from 'component/ui/menu/Menu';
import MenuItem from 'component/ui/menu/MenuItem';
import { ReactComponent as LogoMemoSmall } from 'static/image/logo-memo-small.svg';

const Wrapper = styled.div`
  height: 36px; // 36 + 14 = 50
  width: calc(100% - 40px);
  padding: 7px 20px;
  position: fixed;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 4px 0px rgba(36, 38, 38, 0.05);
  z-index: 10;
`;

const Filler = styled.div`
  flex: 1;
`;

const LogoImage = styled(LogoMemoSmall)`
  cursor: pointer;
`;

const Divider = styled.div`
  margin-top: 4px;
  height: 1px;
  background-color: #e6eaec;
`;

const VersionText = styled.div`
  padding: 12px 16px;
  font-size: 9px;
  color: #8f979d;
`;

function Header(props) {
  const history = useHistory();

  const {
    // Redux state
    authState,
    medicalStaffsState,
    // Redux dispatch
    showDialog,
    hideDialog,
    logout,
  } = props;
  const profile = authState.me;

  return (
    <Wrapper>
      <LogoImage
        onClick={() => {
          history.push('/');
        }}
      />
      <Filler />

      {profile?.username && (
        <>
          <Avatar src={null} />
          <Menu
            style={{ marginLeft: 8 }}
            title={`${profile.hospital?.affiliation ?? ''} ${
              profile.hospital?.hospitalName ?? ''
            } ${profile.lastName}${profile.firstName}님`}>
            <MenuItem
              title={'내 계정'}
              onClick={() => {
                history.push('/my-account');
              }}
            />
            <MenuItem
              title={'비밀번호 변경'}
              onClick={() => {
                history.push('/change-password');
              }}
            />
            {/* <MenuItem
                            title={'사용자 관리'}
                            onClick={() => {
                                history.push('/user-management');
                            }}
                        /> */}
            {/* <MenuItem
                            title={'개인정보 처리방침'}
                            onClick={() => {
                                history.push('/privacy');
                            }}
                        /> */}
            <MenuItem
              title={'로그아웃'}
              onClick={() => {
                logout();
              }}
            />

            <Divider />

            <VersionText>{`Version ${process.env.REACT_APP_VERSION}`}</VersionText>
          </Menu>
        </>
      )}
    </Wrapper>
  );
}

export default Header;
