import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ButtonSpinner from './ButtonSpinner';

const Wrapper = styled.div`
  position: relative;
  width: 100px;
  height: 32px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  ${(props) => {
    let color, borderColor, bgColor, hoverBgColor, fontWeight;
    let fontSize = 13;

    switch (props.color) {
      case 'default':
        color = props.theme.color.DARK;
        borderColor = props.theme.color.MEDIUM;
        bgColor = props.theme.color.LIGHT;
        hoverBgColor = props.theme.color.LIGHT;
        break;
      case 'error':
        color = props.theme.color.RED;
        bgColor = props.theme.color.ULTRA_LIGHT_RED;
        break;
      case 'secondary':
        color = props.disabled
          ? props.theme.color.MEDIUM
          : props.theme.color.BLACK;
        bgColor = props.theme.color.WHITE;
        borderColor = props.theme.color.MEDIUM;
        hoverBgColor = props.theme.color.LIGHT;
        fontSize = 12;
        fontWeight = 500;
        break;
      case 'blackAndWhite':
        color = props.theme.color.DARK;
        bgColor = props.theme.color.WHITE;
        borderColor = props.theme.color.MEDIUM;
        hoverBgColor = props.theme.color.LIGHT;
        fontSize = 12;
        fontWeight = 500;
        break;
      case 'pressed':
        color = props.theme.color.DARK;
        bgColor = props.theme.color.LIGHT;
        hoverBgColor = props.theme.color.LIGHT;
        borderColor = props.theme.color.DARK;
        fontSize = 12;
        fontWeight = 500;
        break;
      case 'primary':
        break;
      default:
        break;
    }
    if (props.outline) {
      return `
        font-size: ${fontSize}px;
        font-weight: ${fontWeight};
        background-color: ${bgColor || props.theme.color.WHITE};
        :hover:not([disabled]) {
            background-color: ${
              hoverBgColor ||
              props.hoverBackgroundColor ||
              props.theme.color.ULTRA_LIGHT_PRIMARY_BLUE
            };
        }
        border: 1px solid ${
          borderColor || color || props.theme.color.PRIMARY_BLUE
        };
        color: ${color || props.theme.color.PRIMARY_BLUE};
        & path {
          fill: ${
            props.disabled
              ? props.theme.color.MEDIUM
              : props.iconColor ||
                borderColor ||
                color ||
                props.theme.color.PRIMARY_BLUE
          };
    }
  `;
    } else {
      return `
        font-size: ${fontSize}px;
        background-color: ${
          bgColor || props.backgroundColor || props.theme.color.PRIMARY_BLUE
        };
        :hover:not([disabled]) {
            background-color: ${
              hoverBgColor ||
              props.hoverBackgroundColor ||
              props.theme.color.MEDIUM_DARK_PRIMARY_BLUE
            };
            color: ${
              color || props.textColor || props.theme.color.MEDIUM_LIGHT
            };
        }
        color: ${color || props.textColor || props.theme.color.WHITE};
  `;
    }
  }}
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  transition: background-color 0.2s ease-in-out;
`;

const rippleAnim = keyframes`
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(4);
    opacity: 0;
  }
`;

/**
 * 클릭시 보여주는 이펙트
 * XXX(준호?): 다운라이즈 시 보여주는 요소 개발에 응용??
 */
const Ripple = styled.span`
  width: ${(props) => props.diameter}px;
  height: ${(props) => props.diameter}px;
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  border-radius: 50%;
  transform: scale(0);
  animation: ${rippleAnim} 600ms linear;
  // background-color: rgba(255, 255, 255, 0.7);
  background-color: ${(props) => {
    if (props.outline) {
      return props.color
        ? props.theme.color.MEDIUM_DARK
        : props.theme.color.LIGHT_PRIMARY_BLUE;
    }
    return props.theme.color.DARK_PRIMARY_BLUE;
  }};
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div``;

/**
 * props.style.width 부여할때 padding: 12px 16px; 과 boder: 1px 고려 필요
 * @param {*} props
 * @returns
 */
function Button(props, ref) {
  const {
    outline,
    color,
    style,
    disabled,
    pending,
    title,
    text,
    backgroundColor,
    hoverBackgroundColor,
    textColor,
    startIcon,
    endIcon,
    onClick,
    iconColor,
  } = props;

  const [rippleAttributes, setRippleAttributes] = useState({
    diameter: 0,
    left: -1,
    top: -1,
  });
  const [showRipple, setShowRipple] = useState(false);

  useEffect(() => {
    if (showRipple) {
      setTimeout(() => {
        setShowRipple(false);
      }, 600);
    }
  }, [showRipple]);

  return (
    <Wrapper
      {...props}
      ref={ref}
      outline={!!outline}
      color={color}
      style={style}
      iconColor={iconColor}
      disabled={disabled || pending}
      onClick={(event) => {
        const rect = event.target.getBoundingClientRect();
        const diameter = Math.max(
          event.target.clientWidth,
          event.target.clientHeight
        );
        const radius = diameter / 2;
        setRippleAttributes({
          diameter: diameter,
          left: event.clientX - rect.left - radius,
          top: event.clientY - rect.top - radius,
        });
        setShowRipple(true);
        if (!(disabled || pending) && typeof onClick === 'function') {
          onClick(event);
        }
      }}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      textColor={textColor}>
      {showRipple && (
        <Ripple outline={!!outline} color={color} {...rippleAttributes} />
      )}

      {/* Start icon */}
      {startIcon && (
        <IconContainer style={{ marginRight: 4 }}>{startIcon}</IconContainer>
      )}

      {/* Title */}
      {pending ? <ButtonSpinner /> : <TextWrapper>{title || text}</TextWrapper>}

      {/* End icon */}
      {endIcon && (
        <IconContainer style={{ marginLeft: 4 }}>{endIcon}</IconContainer>
      )}
    </Wrapper>
  );
}

// export default Button;
export default React.forwardRef(Button);
