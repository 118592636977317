import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import LocalStorageManager from 'manager/LocalStorageManager';
import { DialogWrapper } from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';
import LocalStorageKey from 'constant/LocalStorageKey';
import { ReactComponent as CloseIcon } from 'static/icon/icon-close-x.svg';
import { ReactComponent as RenewalSvg } from 'static/image/renewal-notice.svg';

const SvgWrapper = styled.div`
  cursor: ${({ toGoUrl }) => {
    if (toGoUrl) {
      return 'pointer';
    }
  }};
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

const DialogButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

const DoNotShowTodayWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.color.DARK};
  margin-left: 21px;
  gap: 3px;
  cursor: pointer;
  input {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

function NoticeDialog(props) {
  const intl = useIntl();
  const { open, params, callback, onClose, zIndex } = props;
  const { showCloseIcon, toGoUrl, confirmButtonText } = params;

  const [doNotShowToday, setDoNotShowToday] = useState(false);

  const handleCloseBtnClick = () => {
    if (doNotShowToday) {
      const doNotShowTilThisDate = new Date().setHours(24, 0, 0, 0);

      LocalStorageManager.setItem(
        LocalStorageKey.DO_NOT_SHOW_NOTICE_POPUP_TIL_THIS_DATE,
        JSON.stringify(doNotShowTilThisDate)
      );
    }
    onClose();
  };

  return (
    <DialogWrapper
      zIndex={zIndex ?? 12}
      open={open}
      maxWidth={360}
      innerStyle={{ padding: '0px', position: 'relative' }}>
      <SvgWrapper
        toGoUrl={toGoUrl}
        onClick={() => {
          if (toGoUrl) window.location.replace(`${toGoUrl}`);
        }}>
        <RenewalSvg />
      </SvgWrapper>

      {showCloseIcon && (
        <CloseIconWrapper onClick={handleCloseBtnClick}>
          <CloseIcon />
        </CloseIconWrapper>
      )}

      <DialogButtonContainer>
        <DoNotShowTodayWrapper
          onClick={() => setDoNotShowToday((prev) => !prev)}>
          <input type="checkbox" checked={doNotShowToday} onChange={() => {}} />
          <span>오늘 다시 보지 않기</span>
        </DoNotShowTodayWrapper>
        <TextButton
          title={
            confirmButtonText ||
            intl.formatMessage({
              id: '99-Dialog-Button-title-close',
              description: '팝업 Dialog의 닫기 버튼',
              defaultMessage: '닫기',
            })
          }
          onClick={() => {
            if (typeof callback === 'function') {
              callback();
            }
            handleCloseBtnClick();
          }}
        />
      </DialogButtonContainer>
    </DialogWrapper>
  );
}

export default NoticeDialog;
