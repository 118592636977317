import React from 'react';
import { useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import {
  DialogWrapper,
  DialogButtonContainer,
} from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';
import useDoctorsDropdown from 'component/hook/useDoctorsDropdown';

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
`;

const MessageContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 12px;
`;

const AlertMessageText = styled.div`
  margin-bottom: 8px;
  font-weight: bold;S
  color: ${(props) => props.theme.color.RED};
`;

const MessageText = styled.div`
  color: ${(props) => props.theme.color.BLACK};
`;

function ConfirmDoctorDialog(props) {
  const theme = useTheme();
  const intl = useIntl();

  const { open, params, callback, onClose } = props;
  const {
    title,
    placeholder,
    initValue,
    confirmButtonText,
    onCancel,
    onSubmit,
  } = params;

  const [confirmedBy, DoctorsDropdown] = useDoctorsDropdown(
    placeholder,
    initValue
  );

  return (
    <DialogWrapper zIndex={11} open={open} maxWidth={360}>
      {title && <TitleText>{title}</TitleText>}

      <MessageContainer>
        <DoctorsDropdown
          anchorContainerStyle={{
            height: 16,
            minWidth: 296 - 24 - 2,
          }}
          itemContainerStyle={{
            top: 34,
          }}
        />
      </MessageContainer>

      <DialogButtonContainer>
        <TextButton
          textColor={theme.color.MEDIUM_DARK}
          title={intl.formatMessage({
            id: '99-Dialog-Button-title-cancel',
            description: '팝업 Dialog의 취소 버튼',
            defaultMessage: '취소',
          })}
          onClick={() => {
            onClose();
            if (typeof onCancel === 'function') {
              onCancel();
            }
          }}
        />
        <TextButton
          title={confirmButtonText}
          onClick={() => {
            if (typeof onSubmit === 'function') {
              onSubmit(confirmedBy, onClose);
            }
          }}
        />
      </DialogButtonContainer>
    </DialogWrapper>
  );
}

export default ConfirmDoctorDialog;
