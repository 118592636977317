import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import {
  DialogWrapper,
  DialogTitleText,
  DialogButtonContainer,
} from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';
import { OnlyRadio } from 'component/ui/radio/Radio';
import { CLASS_LABEL_INFOS } from 'util/ClassLabelUtil';

const RadioContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const ClassLabelRadioArea = styled.div`
  width: 90px;
`;

function EditClassLabelDialog(props) {
  const { open, params, onClose } = props;
  const { title, cancleButtonText, onCloseModal, confirmButtonText, onSubmit } =
    params;

  const theme = useTheme();
  const [classification, setClassification] = useState(null);

  return (
    <DialogWrapper open={open}>
      {title && <DialogTitleText>{title}</DialogTitleText>}
      <RadioContainer>
        {CLASS_LABEL_INFOS.slice(0, 5).map((classificationObj) => {
          return (
            <ClassLabelRadioArea key={classificationObj.value}>
              <OnlyRadio
                label={classificationObj.label}
                id={classificationObj.value}
                checked={classification === classificationObj.value}
                onChange={() => {
                  setClassification(classificationObj.value);
                }}
              />
            </ClassLabelRadioArea>
          );
        })}
      </RadioContainer>
      <RadioContainer>
        {CLASS_LABEL_INFOS.slice(5, 10).map((classificationObj) => {
          return (
            <ClassLabelRadioArea key={classificationObj.value}>
              <OnlyRadio
                key={classificationObj.value}
                label={classificationObj.label}
                id={classificationObj.value}
                checked={classification === classificationObj.value}
                onChange={() => {
                  setClassification(classificationObj.value);
                }}
              />
            </ClassLabelRadioArea>
          );
        })}
      </RadioContainer>

      <DialogButtonContainer>
        <TextButton
          textColor={theme.color.MEDIUM_DARK}
          title={cancleButtonText || '취소'}
          onClick={() => {
            onClose();
            onCloseModal();
            setClassification(null);
          }}
        />
        <TextButton
          title={confirmButtonText || '확인'}
          onClick={(e) => {
            onClose();
            if (typeof onSubmit === 'function') {
              onSubmit(classification);
              setClassification(null);
            }
          }}
        />
      </DialogButtonContainer>
    </DialogWrapper>
  );
}

export default EditClassLabelDialog;
