import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import styled, { useTheme } from 'styled-components';
import {
  DialogWrapper,
  DialogContentContainer,
  DialogButtonContainer,
} from 'component/ui/dialog/Dialog';
import UploadingFileList from 'component/ui/list/UploadingFileList';
import TextButton from 'component/ui/button/TextButton';
import { ReactComponent as UploadIcon } from 'static/icon/icon-upload.svg';

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
`;

const DropzoneContainer = styled.div`
  height: 134px;
  padding: 0px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e6eaec;
  border-radius: 6px;
`;

const DropzoneText = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: #8f979d;
`;

function UploadDataDialog(props) {
  const intl = useIntl();
  const theme = useTheme();

  const {
    // Redux Dispatch
    showDialog,
    //
    open,
    params,
    callback,
    onClose,
  } = props;

  const { title, message } = params;

  const [files, setFiles] = useState([]);
  const [progresses, setProgresses] = useState({});

  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles((files) => files.concat(acceptedFiles));
    setProgresses((progresses) => {
      acceptedFiles.map((file) => (progresses[file.name] = false));

      return progresses;
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const removeFileFromList = (targetFile) => {
    setFiles((files) =>
      files.filter((file) => {
        return file.name !== targetFile.name;
      })
    );
    setProgresses((progresses) => {
      delete progresses[targetFile.name];
      return progresses;
    });
  };

  const handleProgressesDone = (targetFileName) => {
    setProgresses((progresses) => {
      progresses[targetFileName] = true;
      return progresses;
    });
  };

  return (
    <DialogWrapper open={open}>
      <TitleText>
        {title ||
          intl.formatMessage({
            id: '99-UploadDataDialog-TitleText-children-01',
            description: '데이터 업로드',
            defaultMessage: '데이터 업로드',
          })}
      </TitleText>

      <DialogContentContainer>
        <DropzoneContainer {...getRootProps()}>
          <UploadIcon />

          <input {...getInputProps()} />

          <DropzoneText>
            {isDragActive
              ? intl.formatMessage({
                  id: '99-UploadDataDialog-DropzoneText-children-01',
                  description: '선택된 파일을 업로드 합니다.',
                  defaultMessage: '선택된 파일을 업로드 합니다.',
                })
              : intl.formatMessage({
                  id: '99-UploadDataDialog-DropzoneText-children-02',
                  description:
                    '이곳을 클릭하거나 여기에 파일을 끌어다 놓으세요',
                  defaultMessage:
                    '이곳을 클릭하거나 여기에 파일을 끌어다 놓으세요',
                })}
          </DropzoneText>
        </DropzoneContainer>

        {files.length > 0 && (
          <UploadingFileList
            files={files}
            onClickDelete={removeFileFromList}
            handleProgressesDone={handleProgressesDone}
          />
        )}
      </DialogContentContainer>

      <DialogButtonContainer>
        <TextButton
          outline
          title={intl.formatMessage({
            id: '99-Dialog-Button-title-close',
            description: '팝업 Dialog의 닫기 버튼',
            defaultMessage: '닫기',
          })}
          onClick={() => {
            for (let fileName in progresses) {
              if (!progresses[fileName]) {
                showDialog('ConfirmDialog', {
                  title: intl.formatMessage({
                    id: '99-UploadDataDialog-ConfirmDialog-title-01',
                    description: '업로드를 중단하시겠습니까?',
                    defaultMessage: '업로드를 중단하시겠습니까?',
                  }),
                  message: intl.formatMessage({
                    id: '99-UploadDataDialog-ConfirmDialog-message-01',
                    description: '아직 업로드 중인 파일이 있습니다.',
                    defaultMessage: '아직 업로드 중인 파일이 있습니다.',
                  }),
                  cancelButtonText: intl.formatMessage({
                    id: '99-UploadDataDialog-ConfirmDialog-cancelButtonText-01',
                    description: '계속 진행',
                    defaultMessage: '계속 진행',
                  }),
                  confirmButtonText: intl.formatMessage({
                    id: '99-UploadDataDialog-ConfirmDialog-confirmButtonText-01',
                    description: '업로드 중단',
                    defaultMessage: '업로드 중단',
                  }),
                  confirmButtonColor: theme.color.RED,
                  onSubmit: () => onClose(),
                });
                return;
              }
            }
            onClose();
          }}
        />
      </DialogButtonContainer>
    </DialogWrapper>
  );
}

export default UploadDataDialog;
